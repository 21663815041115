@use "sass:color";

//
// Base styles
//

.btn {
  // scss-docs-start btn-css-vars
  --#{$prefix}btn-padding-x: #{$btn-padding-x};
  --#{$prefix}btn-padding-y: #{$btn-padding-y};
  --#{$prefix}btn-font-family: #{$btn-font-family};
  @include rfs($btn-font-size, --#{$prefix}btn-font-size);

  --#{$prefix}btn-font-weight: #{$btn-font-weight};
  --#{$prefix}btn-line-height: #{$btn-line-height};
  --#{$prefix}btn-color: #{$body-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-width: #{$btn-border-width};
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-border-radius: #{$btn-border-radius};
  --#{$prefix}btn-box-shadow: #{$btn-box-shadow};
  --#{$prefix}btn-disabled-opacity: #{$btn-disabled-opacity};
  --#{$prefix}btn-focus-box-shadow:
    0 0 0 #{$btn-focus-width} rgb(
      var(
        --#{$prefix}btn-focus-shadow-rgb
      )
      0.5
    );

  // scss-docs-end btn-css-vars

  display: inline-block;
  padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
  font-family: var(--#{$prefix}btn-font-family);
  @include font-size(var(--#{$prefix}btn-font-size));

  font-weight: var(--#{$prefix}btn-font-weight);
  line-height: var(--#{$prefix}btn-line-height);
  color: var(--#{$prefix}btn-color);
  text-align: center;
  justify-content: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  border:
    var(--#{$prefix}btn-border-width) solid
    var(--#{$prefix}btn-border-color);
  @include border-radius(var(--#{$prefix}btn-border-radius));
  @include gradient-bg(var(--#{$prefix}btn-bg));
  @include box-shadow(var(--#{$prefix}btn-box-shadow));
  @include transition($btn-transition);

  letter-spacing: $btn-letter-spacing;
  text-transform: uppercase;

  &:hover {
    color: var(--#{$prefix}btn-hover-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: var(--#{$prefix}btn-hover-bg);
    border-color: var(--#{$prefix}btn-hover-border-color);
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    color: var(--#{$prefix}btn-disabled-color);
    pointer-events: none;
    background-color: var(--#{$prefix}btn-disabled-bg);
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}btn-disabled-border-color);
    opacity: var(--#{$prefix}btn-disabled-opacity);
    @include box-shadow(none);
  }

  &:active,
  &.active,
  &.show,
  .btn-check:checked + &,
  .btn-check:active + & {
    color: var(--#{$prefix}btn-active-color);
    background-color: var(--#{$prefix}btn-active-bg);

    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}btn-active-border-color);
    @include box-shadow(var(--#{$prefix}btn-active-shadow));

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow:
          var(--#{$prefix}btn-active-shadow),
          var(--#{$prefix}btn-focus-box-shadow);
      }
      @else {
        box-shadow: var(--#{$prefix}btn-focus-box-shadow);
      }
    }
  }

  &:focus,
  .btn-check:focus + & {
    color: var(--#{$prefix}btn-hover-color);
    @include gradient-bg(var(--#{$prefix}btn-hover-bg));

    border-color: var(--#{$prefix}btn-hover-border-color);
    outline: 0;

    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow:
        var(--#{$prefix}btn-box-shadow),
        var(--#{$prefix}btn-focus-box-shadow);
    }
    @else {
      box-shadow: var(--#{$prefix}btn-focus-box-shadow);
    }
  }
}

//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @if $color == "light" {
      @include button-variant(
        $value,
        $value,
        $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
        $hover-border: shade-color($value, $btn-hover-border-shade-amount),
        $active-background: shade-color($value, $btn-active-bg-shade-amount),
        $active-border: shade-color($value, $btn-active-border-shade-amount)
      );
    }
    @else if $color == "dark" {
      @include button-variant(
        $value,
        $value,
        $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
        $hover-border: tint-color($value, $btn-hover-border-tint-amount),
        $active-background: tint-color($value, $btn-active-bg-tint-amount),
        $active-border: tint-color($value, $btn-active-border-tint-amount)
      );
    }
    @else {
      @include button-variant($value, $value);
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// scss-docs-end btn-variant-loops

.btn-primary {
  background-color: $btn-solid-primary-bg-color;
  color: $btn-solid-primary-color;
  background-image: $primary-gradient;
  border: none;

  &:hover {
    opacity: $btn-solid-hover-opacity;
    background-color: $btn-solid-primary-hover-bg-color;
  }

  &:focus,
  &.focus {
    box-shadow: $btn-focus-box-shadow;
    background-color: $btn-solid-primary-focus-bg-color;
  }

  &:active,
  &.active {
    background-color: $btn-solid-primary-active-bg-color;
  }

  &:disabled:not(.btn-is-loading),
  &.disabled:not(.btn-is-loading),
  fieldset:disabled & {
    color: $btn-solid-primary-disabled-color;
    background-color: $btn-solid-primary-disabled-bg-color;
    background-image: none;
  }
}

.btn-secondary {
  background-color: $btn-solid-secondary-bg-color;
  color: $btn-solid-secondary-color;
  border: none;

  &:hover {
    opacity: $btn-solid-hover-opacity;
    background-color: $btn-solid-secondary-hover-bg-color;
  }

  &:focus,
  &.focus {
    box-shadow: $btn-focus-box-shadow;
    background-color: $btn-solid-secondary-focus-bg-color;
  }

  &:active,
  &.active {
    background-color: $btn-solid-secondary-active-bg-color;
  }

  &:disabled:not(.btn-is-loading),
  &.disabled:not(.btn-is-loading),
  fieldset:disabled & {
    color: $btn-solid-secondary-disabled-color;
    background-color: $btn-solid-secondary-disabled-bg-color;
  }

  svg {
    stroke: $btn-solid-secondary-color;
  }
}

.btn-outline-primary {
  border-width: $btn-outline-border-width;
  border-style: solid;
  box-shadow: none;
  color: $btn-outline-primary-color;
  background-color: $btn-outline-primary-bg-color;
  border-color: $btn-outline-primary-border-color;
  background-image: none;

  &:hover {
    box-shadow: none;
    text-decoration: none;
    color: $btn-outline-primary-hover-color;
    border-color: $btn-outline-primary-hover-color;
    background-color: $btn-outline-primary-hover-bg-color;
  }

  &:focus,
  &.focus {
    color: $btn-outline-primary-color;
    background-color: $btn-outline-primary-focus-bg-color;
    box-shadow: $btn-focus-box-shadow;
  }

  &:active,
  &.active {
    color: $btn-outline-primary-color;
    background-color: $btn-outline-primary-active-bg-color;
  }

  &:disabled:not(.btn-is-loading),
  &.disabled:not(.btn-is-loading),
  fieldset:disabled & {
    box-shadow: none;
    color: $btn-outline-primary-disabled-color;
    background-color: $btn-outline-primary-disabled-bg-color;
    border-color: $btn-outline-primary-disabled-border-color;
  }
}

.btn-outline-secondary {
  border-width: $btn-outline-border-width;
  border-style: solid;
  box-shadow: none;
  color: $btn-outline-secondary-color;
  background-color: $btn-outline-secondary-bg-color;
  border-color: $btn-outline-secondary-border-color;

  &:hover {
    box-shadow: none;
    text-decoration: none;
    color: $btn-outline-secondary-color;
    background-color: $btn-outline-secondary-hover-bg-color;
  }

  &:focus,
  &.focus {
    color: $btn-outline-secondary-color;
    background-color: $btn-outline-secondary-focus-bg-color;
    box-shadow: $btn-focus-box-shadow;
  }

  &:active,
  &.active {
    color: $btn-outline-secondary-color;
    background-color: $btn-outline-secondary-active-bg-color;
  }

  &:disabled:not(.btn-is-loading),
  &.disabled:not(.btn-is-loading),
  fieldset:disabled & {
    box-shadow: none;
    color: $btn-outline-secondary-disabled-color;
    background-color: $btn-outline-secondary-disabled-bg-color;
    border-color: $btn-outline-secondary-disabled-border-color;
  }

  svg {
    stroke: $btn-outline-secondary-color;
  }
}

.btn-ghost-primary {
  box-shadow: none;
  color: $btn-ghost-primary-color;
  background-color: $btn-ghost-primary-bg-color;

  &:hover {
    box-shadow: none;
    text-decoration: none;
    color: $btn-ghost-primary-hover-color;
    background-color: $btn-ghost-primary-hover-bg-color;
  }

  &:focus,
  &.focus {
    color: $btn-ghost-primary-color;
    background-color: $btn-ghost-primary-focus-bg-color;
    box-shadow: $btn-focus-box-shadow;
  }

  &:active,
  &.active {
    color: $btn-ghost-primary-color;
    background-color: $btn-ghost-primary-active-bg-color;
  }

  &:disabled:not(.btn-is-loading),
  &.disabled:not(.btn-is-loading),
  fieldset:disabled & {
    color: $btn-ghost-primary-disabled-color;
    background-color: $btn-ghost-primary-disabled-bg-color;
  }

  svg {
    stroke: $btn-ghost-primary-color;
  }
}

.btn-ghost-secondary {
  border: none;
  box-shadow: none;
  color: $btn-ghost-secondary-color;
  background-color: $btn-ghost-secondary-bg-color;

  &:hover {
    box-shadow: none;
    text-decoration: none;
    background-color: $btn-ghost-secondary-hover-bg-color;
  }

  &:focus,
  &.focus {
    background-color: $btn-ghost-secondary-focus-bg-color;
    box-shadow: $btn-focus-box-shadow;
  }

  &:active,
  &.active {
    background-color: $btn-ghost-secondary-active-bg-color;
  }

  &:disabled:not(.btn-is-loading),
  &.disabled:not(.btn-is-loading),
  fieldset:disabled & {
    color: $btn-ghost-secondary-disabled-color;
    background-color: $btn-ghost-secondary-disabled-bg-color;
  }

  svg {
    stroke: $btn-ghost-secondary-color;
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  --#{$prefix}btn-font-weight: #{$font-weight-normal};
  --#{$prefix}btn-color: #{$btn-link-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-border-color: transparent;
  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-disabled-color: #{$btn-link-disabled-color};
  --#{$prefix}btn-disabled-border-color: transparent;
  --#{$prefix}btn-box-shadow: none;
  --#{$prefix}btn-focus-shadow-rgb:
    #{to-rgb(
      color.mix(color-contrast($primary), $primary, 15%)
    )};

  text-decoration: $link-decoration;

  &:hover,
  &:focus {
    text-decoration: $link-hover-decoration;
    color: var(--#{$prefix}link-hover-color);
    background: transparent;
  }

  &:disabled {
    background: transparent;
    color: $secondary;
  }

  // No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $btn-font-size-lg,
    $btn-border-radius
  );
}

.btn-sm {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-border-radius
  );
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #e0e0e0;
  border-radius: 100%;
  animation: spinner-border 1s linear infinite;

  &-sm {
    width: 16px;
    height: 16px;
  }

  &-md {
    width: 20px;
    height: 20px;
  }

  &-lg {
    width: 20px;
    height: 20px;
  }

  &-solid {
    border: 2px solid;
    border-bottom-color: $white;
  }
}

.btn-loading {
  &:disabled {
    &:has(*) {
      & > :last-child::after {
        content: " ";
        white-space: pre-wrap;
        position: absolute;
        animation: loading-ellipsis 1s ease-in-out infinite;
      }
    }

    &:not(:has(*)) {
      &::after {
        content: " ";
        white-space: pre-wrap;
        position: absolute;
        animation: loading-ellipsis 1s ease-in-out infinite;
      }
    }
  }
}

.btn-progress {
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    z-index: 1;
    background-color: #0f187a;
    animation: progress-loading var(--animation-duration) linear forwards;
    animation-delay: 1s;
  }

  &.full::before {
    width: 100%;
  }

  @keyframes progress-loading {
    to {
      width: 100%;
    }
  }

  .ellipsis-loader {
    &::after {
      content: " ";
      white-space: pre-wrap;
      position: absolute;
      animation: loading-ellipsis 1s ease-in-out infinite;
    }
  }
}

@keyframes loading-ellipsis {
  0% {
    content: "";
  }

  33% {
    content: ".";
  }

  66% {
    content: "..";
  }

  100% {
    content: "...";
  }
}

.bg-gradient-none {
  background: none;
}
