$home-equity-loan-steps-margin-bottom-mobile: 2rem;
$home-equity-loan-steps-transform-translate-y-mobile: -7vw;
$home-equity-loan-steps-margin-bottom-tablet: 4rem;
$home-equity-loan-steps-transform-translate-y-tablet: -4.6vw;
$home-equity-loan-steps-margin-bottom-desktop: 6rem;
$home-equity-loan-steps-transform-translate-y-desktop: -7vw;

.home-equity-loan-bg {
  background: linear-gradient(118deg, #380a62 -28.75%, #0e0c83 128.75%);
  @include media-breakpoint-up(md) {
    background: linear-gradient(90deg, #390b63 0%, #180c7d 89.58%, #140c80 100%);
  }
}

.home-equity-loan-svg-24 {
  height: 24px;
  width: 24px;
}

.home-equity-loan-svg-32-md {
  @include media-breakpoint-up(md) {
    height: 32px;
    width: 32px;
  }
}

.home-equity-loan-svg-32-xl {
  @include media-breakpoint-up(xl) {
    height: 32px;
    width: 32px;
  }
}

.home-equity-loan-steps-icon {
  height: max-content;
}

.home-equity-loan-steps {
  transform: translateY($home-equity-loan-steps-transform-translate-y-mobile);
  margin-bottom:
    calc(
      $home-equity-loan-steps-margin-bottom-mobile + $home-equity-loan-steps-transform-translate-y-mobile
    );
  @include media-breakpoint-up(md) {
    transform: translateY($home-equity-loan-steps-transform-translate-y-tablet);
    margin-bottom:
      calc(
        $home-equity-loan-steps-margin-bottom-tablet + $home-equity-loan-steps-transform-translate-y-tablet
      );
  }
  @include media-breakpoint-up(xl) {
    transform: translateY($home-equity-loan-steps-transform-translate-y-desktop);
    margin-bottom:
      calc(
        $home-equity-loan-steps-margin-bottom-desktop +
        $home-equity-loan-steps-transform-translate-y-desktop
      );
  }
}

.home-equity-sticky-button {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: auto;
  }
}
