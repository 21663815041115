@use "sass:map";

$summary-item-width: 98px;
$summary-item-width-md: 190px;

.summary-container {
  .summary-item {
    width: $summary-item-width;
    @include media-breakpoint-up(md) {
      width: $summary-item-width-md;
    }
  }

  /*
    Add padding-top to 3rd element if it
    wraps to a second row on small devices
  */
  .summary-item:nth-child(3n + 1)::after {
    content: "";
    padding-top: 1rem;
  }

  .summary-body {
    @include media-breakpoint-up(xl) {
      padding: 2.5rem 0;
    }
  }

  .summary-card {
    box-shadow: none;

    @include media-breakpoint-up(xl) {
      box-shadow: $box-shadow;
    }
  }
}
