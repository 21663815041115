.tag {
  @extend .typeface-body-small;

  border-radius: $border-radius * 0.5;
  padding: 0 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
}

.tag-solid {
  background-color: $primary-06;
  color: $white;
}

.tag-primary-outline {
  background-color: $white;
  border: 1px solid $primary-06;
  color: $primary-06;
}

.tag-secondary {
  background-color: $black;
  color: $white;
}

.tag-secondary-outline {
  background-color: $white;
  border: 1px solid $black;
  color: $black;
}
