@use "sass:map";

.products-page-container {
  .floating-summary-container {
    @include media-breakpoint-up($main-hero-breakpoint) {
      position: absolute;
      width: 100%;
      z-index: 2;
      bottom: -120px;
    }
  }

  .floating-cta-container {
    @include media-breakpoint-up($main-hero-breakpoint) {
      position: absolute;
      width: 100%;
      z-index: 2;
      bottom: 24rem;
    }

    @include media-breakpoint-up("xxl") {
      position: absolute;
      width: 100%;
      z-index: 2;
      bottom: 23rem;
    }
  }

  /** classes that accommodate the floating sections */

  /** for cash out PDP v1, and other PDPs */
  .products-page-extra-top-padding {
    @include media-breakpoint-up($main-hero-breakpoint) {
      padding-top: 8rem !important;
    }
  }

  .products-page-extra-bottom-padding {
    @include media-breakpoint-up($main-hero-breakpoint) {
      padding-bottom: 13rem !important;
    }
  }

  /** for cash out PDP v2 (april 2024) */
  .floating-container-spacing-top {
    @include media-breakpoint-up($main-hero-breakpoint) {
      padding-top: 8rem !important;
    }
  }

  .floating-container-spacing-bottom {
    @include media-breakpoint-up($main-hero-breakpoint) {
      padding-bottom: 8rem !important;
    }
  }
}
