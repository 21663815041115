////
/// Effects
///
/// This file contains values for box shadows used in the UI library
///
////

// scss-docs-start elevation
$elevation-01: 0 1px 2px rgb(0 0 0 / 5%);
$elevation-02: 0 4px 8px -2px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 6%);
$elevation-03: 0 12px 16px -4px rgb(0 0 0 / 8%), 0 4px 6px -2px rgb(0 0 0 / 3%);
$elevation-04: 0 20px 24px -4px rgb(0 0 0 / 8%), 0 8px 8px -4px rgb(0 0 0 / 3%);
$elevation-05: 0 32px 64px 0 rgb(0 0 0 / 25%), 0 0 1px 0 rgb(0 0 0 / 20%);
$elevation-08: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 0 1px 0 rgb(0 0 0 / 20%);
$elevation-16: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 0 1px 0 rgb(0 0 0 / 20%);

// scss-docs-end elevation

// scss-docs-start reverse-elevation
$reverse-elevation-01: 0 -1px 2px rgb(0 0 0 / 5%);
$reverse-elevation-02: 0 -4px 8px -2px rgb(0 0 0 / 10%),
  0 -2px 4px -2px rgb(0 0 0 / 6%);
$reverse-elevation-03: 0 -12px 16px 4px rgb(0 0 0 / 8%),
  0 -4px 6px 2px rgb(0 0 0 / 3%);
$reverse-elevation-04: 0 -12px 24px 4px rgb(0 0 0 / 8%),
  0 -8px 8px 4px rgb(0 0 0 / 3%);

// scss-docs-end reverse-elevation

// scss-docs-start text-shadow
$text-shadow-primary: 0 0 19px rgba($primary-06, 0.75);

// scss-docs-end text-shadow
