// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y: 0.5rem;
$navbar-padding-x: 1.5rem;
$navbar-padding-transition: 0.2s;
$navbar-nav-link-padding-x: 0.5rem !default;
$navbar-brand-font-size: $font-size-sm;
$navbar-height: 64px;
$navbar-desktop-height: 80px;

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) * 0.5 !default;
$navbar-brand-margin-end: 1rem !default;
$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;
$navbar-toggler-focus-width: $btn-focus-width !default;
$navbar-toggler-transition: box-shadow 0.15s ease-in-out !default;

// scss-docs-end navbar-variables

// scss-docs-start navbar-theme-variables
$navbar-dark-color: rgba($white, 0.55) !default;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;
$navbar-light-color: $black !default;
$navbar-light-padding-x: 0;
$navbar-light-border-bottom: none;
$navbar-light-menu-link-border-color: $light-pink !default;
$navbar-light-menu-link-background-color: $white-01 !default;
$navbar-light-menu-dropdown-background-color: $primary-01 !default;
$navbar-light-menu-dropdown-box-shadow-color: 0 0.9rem 1.5rem #{$navbar-light-color}4d !default;
$navbar-light-background-color: var(--primary-01) !default;
$navbar-light-btn-color: currentcolor !default;
$navbar-light-hover-color: $primary-gradient !default;
$navbar-light-active-color: rgba($black, 0.9) !default;
$navbar-light-disabled-color: rgba($black, 0.3) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, 0.1) !default;
$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;

// scss-docs-end navbar-theme-variables

// scss-docs-start navbar-desktop-paddings
$navbar-desktop-padding-y: 0.5rem;
$navbar-desktop-padding-x: 1.5rem;

// scss-docs-end navbar-desktop-paddings
