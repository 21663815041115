$lakeview-subtle: #f7f7ff;
$lakeview-highlight: #4398d4;

.bg-gradient-lakeview {
  background:
    linear-gradient(
      90deg,
      rgb(32 57 96 / 100%) 0%,
      rgb(69 148 198 / 100%) 100%
    );
}

.bg-lakeview {
  background-color: $lakeview-subtle;
}

.border-lakeview {
  border-left: 4px solid $lakeview-highlight;
}
