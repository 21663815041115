@use "sass:map";

////
/// Color system
/// Variables that declare the colors used in Purple rain
///
/// This file provides maps with the different shades of the main colors
/// and other variables for shading, states, and notification colors.
////

// COLORS DEFINITIONS

// scss-docs-start gray-color-variables
$white: #fff;
$white-01: #fefbfe;
$gray-01: #f9fafb;
$gray-02: #f3f4f6;
$gray-03: #e5e7eb;
$gray-04: #d2d6db;
$gray-05: #9da4ae;
$gray-06: #6c737f;
$gray-07: #4d5761;
$gray-08: #384250;
$black: #050461;
$standard-black: #000;

// scss-docs-end gray-color-variables

// scss-docs-start primary-color-variables
$primary-01: var(--primary-01);
$primary-02: #e5c9ff;
$primary-03:var(--primary-03);
$primary-04: #c27eff;
$primary-05: #b059ff;
$primary-06: #9c46ea;
$primary-07: #8132c8;
$primary-08: #6721a6;
$primary-09: #4f1484;
$primary-10: #380a62;

// scss-docs-end primary-color-variables

// scss-docs-start secondary-colors-variables
$secondary-01: #f7fff7;
$secondary-02: #d5ffd7;
$secondary-03: #b4ffb6;
$secondary-06: #77b979;
$secondary-07: #639f65;
$secondary-08: #518652;

// scss-docs-end secondary-colors-variables

// scss-docs-start notification-colors
$error-01: #f04438;
$error-10: #fff8f6;
$alert-01: #f79009;
$alert-10: #fffcf5;
$success-01: #2ac961;
$success-10: #f6f7ed;
$info-01: #2e90fa;
$info-10: #f5faff;

// scss-docs-end notification-colors

// scss-docs-start brand-colors
$dark-green: #d5e9e4;
$light-green: #00f900;
$light-pink: #e1d8de;
$indigo-1: #6d6ad6;
$indigo-2: #525186;
$purple-1: #dee4ff;
$purple-2: #9958ff;
$purple-3: #edf0ff;
$purple-4: #d7d5ff;
$magenta-1: #ff01b0;
$magenta-2: #ff81f2;
$orange-1: #ffd564;
$orange-2: #fffaee;
$orange-3: #fff8f6;
$orange-4: #ffbda8;
$teal-01: #98d5c7;
$teal-02: #7ab8a9;
$teal-03: #5da291;
$teal-04: #3c8f7c;
$red-1: #cc071e;
$subtle: #d8dae9;

// scss-docs-end brand-colors

// scss-docs-start typography-colors
$typography-default-color: $black;
$typography-dark-background-color: $white;

// scss-docs-end typography-colors

// scss-docs-start surface-colors
$surface-default: $white;
$surface-outline: rgba($black, 0.3);
$surface-overlay: rgba($black, 0.2);
$dark-surface-default: #101a24;

// scss-docs-end surface-colors

// scss-docs-start theme-color-variables
$primary: $primary-07;
$secondary: $secondary-07;
$success: $success-01;
$info: $info-01;
$warning: $alert-01;
$danger: $error-01;
$light: $surface-default;
$dark: $dark-surface-default;
$link: $primary-08;

// scss-docs-end theme-color-variables

// MAPPINGS

// scss-docs-start gray-colors-map
$grays: (
  "gray-01": $gray-01,
  "gray-02": $gray-02,
  "gray-03": $gray-03,
  "gray-04": $gray-04,
  "gray-05": $gray-05,
  "gray-06": $gray-06,
  "gray-07": $gray-07,
  "gray-08": $gray-08,
);

// scss-docs-end gray-colors-map

// scss-docs-start primary-colors-map
$primaries: (
  "primary-01": $primary-01,
  "primary-02": $primary-02,
  "primary-03": $primary-03,
  "primary-04": $primary-04,
  "primary-05": $primary-05,
  "primary-06": $primary-06,
  "primary-07": var(--primary-07),
  "primary-08": $primary-08,
);

// scss-docs-end primary-colors-map

// scss-docs-start secondary-colors-map
$secondaries: (
  "secondary-01": $secondary-01,
  "secondary-02": $secondary-02,
  "secondary-03": $secondary-03,
  "secondary-06": $secondary-06,
  "secondary-07": $secondary-07,
  "secondary-08": $secondary-08,
);

// scss-docs-end secondary-colors-map

// scss-docs-start notification-colors-map
$notification: (
  "notification-error-bold": $error-01,
  "notification-error-subtle": $error-10,
  "notification-alert-bold": $alert-01,
  "notification-alert-subtle": $alert-10,
  "notification-success-bold": $success-01,
  "notification-success-subtle": $success-10,
  "notification-info-bold": $info-01,
  "notification-info-subtle": $info-10,
);

// scss-docs-end notification-colors-map

// scss-docs-start brand-colors-map
$brand-colors: (
  "dark-green": $dark-green,
  "light-green": $light-green,
  "light-pink": $light-pink,
  "indigo-1": $indigo-1,
  "indigo-2": $indigo-2,
  "purple-1": $purple-1,
  "purple-2": $purple-2,
  "purple-3": $purple-3,
  "purple-4": $purple-4,
  "magenta-1": $magenta-1,
  "magenta-2": $magenta-2,
  "orange-1": $orange-1,
  "orange-2": $orange-2,
  "orange-3": $orange-3,
  "orange-4": $orange-4,
  "teal-01": $teal-01,
  "teal-02": $teal-02,
  "teal-03": $teal-03,
  "teal-04": $teal-04,
  "red-1": $red-1,
);

// scss-docs-end brand-colors-map

// scss-docs-start typography-colors-map
$typography: (
  "typography-surface-high-emphasis": rgba($typography-default-color, 1),
  "typography-surface-medium-emphasis": rgba($typography-default-color, 0.75),
  "typography-surface-disabled": rgba($typography-default-color, 0.38),
  "typography-dark-background-high-emphasis": rgba($typography-dark-background-color, 0.95),
  "typography-dark-background-medium-emphasis": rgba($typography-dark-background-color, 0.75),
  "typography-dark-background-disabled": rgba($typography-dark-background-color, 0.38),
);

// scss-docs-end typography-colors-map

// scss-docs-start surface-state-map
$states: (
  "state-primary-default": rgba($primary, 0.08),
  "state-primary-hover": rgba($primary, 0.04),
  "state-primary-focus": rgba($primary, 0.12),
  "state-primary-selected": rgba($primary, 0.08),
  "state-primary-activated": rgba($primary, 0.12),
  "state-primary-pressed": rgba($primary, 0.16),
  "state-primary-dragged": rgba($primary, 0.08),
  "state-secondary-default": rgba($secondary, 0.08),
  "state-secondary-hover": rgba($secondary, 0.04),
  "state-secondary-focus": rgba($secondary, 0.12),
  "state-secondary-selected": rgba($secondary, 0.08),
  "state-secondary-activated": rgba($secondary, 0.12),
  "state-secondary-pressed": rgba($secondary, 0.16),
  "state-secondary-dragged": rgba($secondary, 0.08),
  "state-dark-surface-default": rgba($light, 0.1),
  "state-dark-surface-hover": rgba($light, 0.04),
  "state-dark-surface-focus": rgba($light, 0.12),
  "state-dark-surface-selected": rgba($light, 0.08),
  "state-dark-surface-activated": rgba($light, 0.12),
  "state-dark-surface-pressed": rgba($light, 0.16),
  "state-dark-surface-dragged": rgba($light, 0.08),
  "state-surface-default": rgba($dark, 0.08),
  "state-surface-hover": rgba($dark, 0.04),
  "state-surface-focus": rgba($dark, 0.12),
  "state-surface-selected": rgba($dark, 0.08),
  "state-surface-activated": rgba($dark, 0.12),
  "state-surface-pressed": rgba($dark, 0.16),
  "state-surface-dragged": rgba($dark, 0.08),
);

// scss-docs-end surface-state-map

// scss-docs-start surface-map
$surface: (
  "surface-default": $surface-default,
  "surface-outline": $surface-outline,
  "surface-overlay": $surface-overlay,
);

// scss-docs-end surface-map

// scss-docs-start colors-map
$colors: (
  "teal": $teal-03,
);

// scss-docs-end colors-map

// scss-docs-start boostrap-default-colors-map
$bootstrap-default-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "link": $link,
);

// scss-docs-end bootstrap-default--colors-map

// Theme colors
// scss-docs-start theme-colors
$theme-colors: $bootstrap-default-colors;

// scss-docs-end theme-colors

// scss-docs-start purple-rain-theme-colors
@function map-collect($maps...) {
  $collection: (
  );

  @each $map in $maps {
    $collection: map.merge($collection, $map);
  }

  @return $collection;
}

$purple-rain-colors: map-collect(
  $grays,
  $primaries,
  $secondaries,
  $notification,
  $brand-colors,
  $typography,
  $states,
  $surface
);

// scss-docs-end purple-rain-theme-colors

// scss-docs-end purple-rain-theme-colors
// The contrast ratio to reach against white, to determine if color changes from "light" to "dark".
// Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;
