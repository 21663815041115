@use "sass:map";

.back-button {
  $self: &;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
    background-color: transparent;
    border-color: transparent;
  }

  &__svg {
    width: 12px;

    path {
      stroke: $primary-08;
    }
  }

  &__text {
    color: $primary-08;
  }

  &--white {
    #{ $self }__svg {
      path {
        stroke: map.get($typography, "typography-dark-background-high-emphasis");
      }
    }

    #{ $self }__text {
      color: map.get($typography, "typography-dark-background-high-emphasis");
    }
  }
}
