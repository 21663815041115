@use "sass:map";

.personal-goals {
  &__link {
    @extend a;

    cursor: pointer;
  }

  &__card {
    width: 100%;

    @include media-breakpoint-up("md") {
      width: calc(50% - 8px);
    }

    @include media-breakpoint-up("xl") {
      min-width: 327px;
    }
  }

  &__button {
    flex: none;
  }

  &__cash-back {
    width: 100%;
    @include media-breakpoint-up("xl") {
      width: 306px;
    }
  }
}

$desktop-button-height: 152px;
$desktop-button-width: 154px;

.equity-planner-accordion {
  &__card {
    height: $desktop-button-height;
    width: $desktop-button-width;
  }

  &__container {
    width: 0;
    overflow: hidden;

    &.expanded {
      width: calc(100% - $desktop-button-width);
    }
  }

  &__content {
    height: $desktop-button-height;
  }
}
