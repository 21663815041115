// scss-docs-start close-variables
$btn-close-width: 1em !default;
$btn-close-height: $btn-close-width !default;
$btn-close-padding-x: 0.25em !default;
$btn-close-padding-y: $btn-close-padding-x !default;
$btn-close-color: $primary !default;
$btn-close-bg: url("../icons/close-outline.svg");
$btn-close-focus-shadow: $input-btn-focus-box-shadow !default;
$btn-close-opacity: 0.5 !default;
$btn-close-hover-opacity: 0.75 !default;
$btn-close-focus-opacity: 1 !default;
$btn-close-disabled-opacity: 0.25 !default;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%) !default;

// scss-docs-end close-variables
