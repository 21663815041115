@use "sass:map";

//
// Base styles
//

.alert {
  // scss-docs-start alert-css-vars
  --#{$prefix}alert-bg: #{$white};
  --#{$prefix}alert-padding-x: #{$alert-padding-x};
  --#{$prefix}alert-padding-y: #{$alert-padding-y};
  --#{$prefix}alert-margin-bottom: #{$alert-margin-bottom};
  --#{$prefix}alert-color: inherit;
  --#{$prefix}alert-border-color: transparent;
  --#{$prefix}alert-border: #{$alert-border-width} solid var(--#{$prefix}alert-border-color);
  --#{$prefix}alert-border-radius: #{$alert-border-radius};
  --#{$prefix}alert-btn-close-color: inherit;
  --#{$prefix}gradient: none;

  // scss-docs-end alert-css-vars

  position: relative;
  padding: var(--#{$prefix}alert-padding-y) var(--#{$prefix}alert-padding-x);
  margin-bottom: var(--#{$prefix}alert-margin-bottom);
  color: var(--#{$prefix}alert-color);
  background-color: var(--#{$prefix}alert-bg);
  border: var(--#{$prefix}alert-border);
  @include border-radius(var(--#{$prefix}alert-border-radius));
}

// Special styling for banners
.alert-banner {
  --#{$prefix}alert-color: #{$alert-banner-color};
  --#{$prefix}alert-border: #{$alert-banner-border};
  --#{$prefix}alert-border-radius: #{$alert-banner-border-radius};

  box-shadow: $elevation-02;

  @each $state in $alert-states {
    .alert-#{$state} {
      --#{$prefix}alert-bg: #{map.get($alert-banner-bg, $state)};
    }
  }
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $alert-dismissible-padding-r;

  // Adjust close link position
  .btn-close {
    background-color: var(--#{$prefix}alert-btn-close-color);
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    right: 0;
    opacity: 1;
    z-index: $stretched-link-z-index + 1;
    padding: $alert-padding-y * 1.25 $alert-padding-x;
  }
}

// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert.

@each $state in $alert-states {
  .alert-#{$state} {
    --#{$prefix}alert-border-color: transparent;
    --#{$prefix}alert-border-color: #{map.get($alert-border-color, $state)};
    --#{$prefix}alert-border-radius: #{$alert-border-radius};
    --#{$prefix}alert-btn-close-color:
      #{map.get(
        $alert-btn-close-color,
        $state
      )};
  }
}

// scss-docs-end alert-modifiers
