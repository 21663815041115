@use "sass:map";

$steps-vl-width: 0.375rem;
$steps-vl-state-white-surface-default: map.get(
  $purple-rain-colors,
  "state-surface-pressed"
);
$steps-selection-width-xl: 357px;
$steps-selection-width-md: 329px;
$steps-title-padding-left: 0.625rem;

.steps {
  .steps-selected-content {
    width: calc(100% - $steps-selection-width-md);
    min-height: 100%;
    @include media-breakpoint-up("xl") {
      width: calc(100% - $steps-selection-width-xl);
    }
  }

  .steps-vl-wrapper {
    width: $steps-vl-width;
  }

  .steps-vl-not-active {
    background-color: $steps-vl-state-white-surface-default;
    width: calc($steps-vl-width/2);
    transform: translateX(50%);
  }

  .steps-vl-active {
    border-left: $primary-05 solid $steps-vl-width;
  }

  .step-content-unactive {
    opacity: 0;
  }

  .step-content-active {
    opacity: 1;
  }
}

/*
  This class is outside of the block because it is used
  in a SignUp (Account Creation) component due to the
  "white-space: break-spaces"
*/
.steps-title {
  padding-left: $steps-title-padding-left !important;
  white-space: break-spaces;
}
