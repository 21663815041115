@use "sass:map";

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Disabling this rule as we will move some of these variables on separate files and because
// of dependencies on other variables they cannot be imported on the beginning of the file.
// stylelint-disable no-invalid-position-at-import-rule

// Color system
@import "./variables/colors";
@import "./variables/colors_v2";

// Effects - Box shadows
@import "./variables/effects";

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29")
) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: false;
$enable-grid-classes: true !default;
$enable-cssgrid: false !default;
$enable-container-classes: true !default;
$enable-button-pointers: true !default;
$enable-rfs: false !default;
$enable-validation-icons: true !default;
$enable-negative-margins: true !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

// Prefix for :root CSS variables

$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;

// Adding a custom prefix to distinguish between the library
// custom variables and those generated by bootstrap
$ui-library-prefix: aq-;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(180deg, $white 0%, rgba($black, 0) 100%);
$bg-gradient: linear-gradient(104.68deg, $primary-10 15.02%, #0e0c83 100%);
$primary-gradient: linear-gradient(90deg, #8132c8 0%, #9c46ea 100%);
$hero-banner-gradient: linear-gradient(
  90deg,
  rgba($standard-black, 0.7) 0%,
  rgba($standard-black, 0) 100%
);

// scss-docs-end variable-gradient

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  // 4px
  1: $spacer * 0.25,
  // 8px
  2: $spacer * 0.5,
  // 16px
  3: $spacer,
  // 24px
  4: $spacer * 1.5,
  // 32px
  5: $spacer * 2,
  // 48px
  6: $spacer * 3,
  // 64px
  7: $spacer * 4,
  // 12px space
  "12px": $spacer * 0.75,
  // 40px space
  "40px": $spacer * 2.5,
  // 96px space
  "96px": $spacer * 6,
  // 120px space
  "120px": $spacer * 7.5,
  // 144px space
  "144px": $spacer * 9,
  // Spacers V2
  "s2": $spacer * 0.125,
  "s4": $spacer * 0.25,
  "s6": $spacer * 0.375,
  "s8": $spacer * 0.5,
  "s12": $spacer * 0.75,
  "s14": $spacer * 0.875,
  "s16": $spacer,
  "s18": $spacer * 1.125,
  "s20": $spacer * 1.25,
  "s24": $spacer * 1.5,
  "s32": $spacer * 2,
  "s36": $spacer * 2.26,
  "s40": $spacer * 2.5,
  "s48": $spacer * 3,
  "s52": $spacer * 3.25,
  "s56": $spacer * 3.5,
  "s64": $spacer * 4,
  "s80": $spacer * 5,
  "s84": $spacer * 5.25,
  "s96": $spacer * 6,
  "s128": $spacer * 8,
  "s152": $spacer * 9.5,
) !default;

// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
) !default;

// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: map.get($purple-rain-colors, "typography-surface-high-emphasis");
$body-text-align: null !default;

// Links
//
// Style anchor elements.

$link-color: $link !default;
$link-decoration: underline !default;
$link-shade-percentage: 20% !default;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration: null !default;
$stretched-link-pseudo-element: after !default;
$stretched-link-z-index: 1 !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// Bayview breakpoints mappings:
// Small - sm
// Medium - md
// Large - lg
// Max - xl

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 564px,
  md: 744px,
  lg: 984px,
  xl: 1164px,
  xxl: 1344px,
) !default;

// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 1.5rem !default;
$grid-row-columns: 6 !default;

// Container padding

$container-padding-x: 3rem;

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width: 1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
) !default;
$border-style: solid !default;
$border-color: $gray-03 !default;
$border-color-translucent: rgba($black, 0.175) !default;

// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: 0.25rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-xl: 1rem !default;
$border-radius-2xl: 2rem !default;
$border-radius-pill: 50rem !default;
$border-radius1: 0.0625rem; // 1px
$border-radius2: 0.125rem; // 2px
$border-radius3: 0.1875rem; // 3px
$border-radius4: 0.25rem; // 4px

// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow: $elevation-02 !default;
$box-shadow-sm: $elevation-01 !default;
$box-shadow-lg: $elevation-03 !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075) !default;

// scss-docs-end box-shadow-variables

$component-active-color: $white !default;
$component-active-bg: $primary !default;
$component-active-link: $link !default;

// scss-docs-start caret-variables
$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;

// scss-docs-end caret-variables

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;

// scss-docs-start collapse-transition
$transition-collapse: height 0.35s ease !default;
$transition-collapse-width: width 0.35s ease !default;
$transition-collapse-max-height: max-height 0.35s ease;

// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
) !default;

// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
@import "./variables/typography";

$hr-margin-y: $spacer !default;
$hr-color: inherit !default;

// fusv-disable
$hr-bg-color: null !default; // Deprecated in v5.2.0
$hr-height: null !default; // Deprecated in v5.2.0
// fusv-enable

$hr-border-color: null !default; // Allows for inherited colors
$hr-border-width: $border-width !default;
$hr-opacity: 0.25 !default;
$list-inline-padding: 0.5rem !default;
$mark-padding: 0.1875em !default;
$mark-bg: $secondary !default;

// scss-docs-end type-variables

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: 0.5rem !default;
$table-cell-padding-x: 0.5rem !default;
$table-cell-padding-y-sm: 0.25rem !default;
$table-cell-padding-x-sm: 0.25rem !default;
$table-cell-vertical-align: top !default;
$table-color: var(--#{$prefix}body-color) !default;
$table-bg: transparent !default;
$table-accent-bg: transparent !default;
$table-th-font-weight: null !default;
$table-striped-color: $table-color !default;
$table-striped-bg-factor: 0.05 !default;
$table-striped-bg: rgba($black, $table-striped-bg-factor) !default;
$table-active-color: $table-color !default;
$table-active-bg-factor: 0.1 !default;
$table-active-bg: rgba($black, $table-active-bg-factor) !default;
$table-hover-color: $table-color !default;
$table-hover-bg-factor: 0.075 !default;
$table-hover-bg: rgba($black, $table-hover-bg-factor) !default;
$table-border-factor: 0.1 !default;
$table-border-width: $border-width !default;
$table-border-color: var(--#{$prefix}border-color) !default;
$table-striped-order: odd !default;
$table-striped-columns-order: even !default;
$table-group-separator-color: currentcolor !default;
$table-caption-color: $text-muted !default;
$table-bg-scale: -80% !default;

// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  "primary": shift-color($primary, $table-bg-scale),
  "secondary": shift-color($secondary, $table-bg-scale),
  "success": shift-color($success, $table-bg-scale),
  "info": shift-color($info, $table-bg-scale),
  "warning": shift-color($warning, $table-bg-scale),
  "danger": shift-color($danger, $table-bg-scale),
  "light": $light,
  "dark": $dark,
) !default;

// scss-docs-end table-loop

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.97rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;
$input-btn-focus-width: 0.25rem !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba(
  $component-active-bg,
  $input-btn-focus-color-opacity
) !default;
$input-btn-focus-blur: 0 !default;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
  $input-btn-focus-color !default;
$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $font-size-lg !default;
$input-btn-border-width: $border-width !default;
$input-btn-focus-width: 0.25rem !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba(
  $component-active-bg,
  $input-btn-focus-color-opacity
) !default;
$input-btn-focus-blur: 0 !default;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
  $input-btn-focus-color !default;
$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $font-size-lg !default;
$input-btn-border-width: $border-width !default;

// scss-docs-end input-btn-variables

// Button variables
@import "./variables/buttons";

// Forms
@import "./variables/form-control";
@import "./variables/form_check";

// scss-docs-start input-group-variables
$input-group-addon-padding-y: $input-padding-y !default;
$input-group-addon-padding-x: $input-padding-x !default;
$input-group-addon-font-weight: $input-font-weight !default;
$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $white;
$input-group-addon-border-color: $input-border-color !default;

// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-padding-y: $input-padding-y !default;
$form-select-padding-x: $input-padding-x !default;
$form-select-font-family: $input-font-family !default;
$form-select-font-size: $input-font-size !default;
$form-select-indicator-padding: $form-select-padding-x * 3 !default; // Extra padding for background-image
$form-select-font-weight: $input-font-weight !default;
$form-select-line-height: $input-line-height !default;
$form-select-color: $input-color !default;
$form-select-bg: $input-bg !default;
$form-select-disabled-color: null !default;
$form-select-disabled-bg: $gray-02 !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;
$form-select-bg-position: right $form-select-padding-x center !default;
$form-select-bg-size: 24px !default; // In pixels because image dimensions
$form-select-indicator-color: $gray-08 !default;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'><path fill='#000' fill-opacity='.75' d='m10.9057 14.3-2.59998-2.6c-.31666-.3167-.38733-.679-.212-1.087.17467-.4087.48701-.613.93701-.613h5.14997c.45 0 .7624.2043.937.613.1754.408.1047.7703-.212 1.087l-2.6 2.6c-.1.1-.2083.175-.325.225-.1166.05-.2416.075-.375.075-.1333 0-.2583-.025-.375-.075-.1166-.05-.225-.125-.325-.225z'/></svg>") !default;
$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 +
  $form-select-indicator-padding !default;
$form-select-feedback-icon-position: center right $form-select-indicator-padding !default;
$form-select-feedback-icon-size: $input-height-inner-half
  $input-height-inner-half !default;
$form-select-border-width: $input-border-width !default;
$form-select-border-color: $input-border-color !default;
$form-select-border-radius: $input-border-radius !default;
$form-select-box-shadow: $box-shadow-inset !default;
$form-select-focus-border-color: $input-focus-border-color !default;
$form-select-focus-width: $input-focus-width !default;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width
  $input-btn-focus-color !default;
$form-select-padding-y-sm: $input-padding-y-sm !default;
$form-select-padding-x-sm: $input-padding-x-sm !default;
$form-select-font-size-sm: $input-font-size-sm !default;
$form-select-border-radius-sm: $input-border-radius-sm !default;
$form-select-padding-y-lg: $input-padding-y-lg !default;
$form-select-padding-x-lg: $input-padding-x-lg !default;
$form-select-font-size-lg: $input-font-size-lg !default;
$form-select-border-radius-lg: $input-border-radius-lg !default;
$form-select-transition: $input-transition !default;

// scss-docs-end form-select-variables

@import "./variables/form-range";

// scss-docs-start form-file-variables
$form-file-button-color: $input-color !default;
$form-file-button-bg: $input-group-addon-bg !default;
$form-file-button-hover-bg: shade-color($form-file-button-bg, 5%) !default;

// scss-docs-end form-file-variables

@import "./variables/floating-labels";

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-margin-top: $form-text-margin-top !default;
$form-feedback-font-size: $form-text-font-size !default;
$form-feedback-font-style: $form-text-font-style !default;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;
$form-feedback-icon-valid-color: $form-feedback-valid-color !default;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg fill='none' height='21' viewBox='0 0 20 21' width='20' xmlns='http://www.w3.org/2000/svg'><path clip-rule='evenodd' d='m9 13.5h2v2h-2zm0-8h2v6h-2zm.99-5c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' fill='#f04438' fill-rule='evenodd'/></svg>");

// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid,
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid,
  ),
) !default;

// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-offcanvas-backdrop: 1040 !default;
$zindex-offcanvas: 1045 !default;
$zindex-modal-backdrop: 1050 !default;
$zindex-modal: 1055 !default;
$zindex-popover: 1070 !default;
$zindex-tooltip: 1080 !default;
$zindex-toast: 1090 !default;

// scss-docs-end zindex-stack

// scss-docs-start zindex-levels-map
$zindex-levels: (
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
) !default;

// scss-docs-end zindex-levels-map

// Navs

// scss-docs-start nav-variables
$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-font-size: null !default;
$nav-link-font-weight: $font-weight-bold !default;
$nav-link-color: var(--#{$prefix}link-color) !default;
$nav-link-hover-color: var(--#{$prefix}link-hover-color) !default;
$nav-link-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out !default;
$nav-link-disabled-color: $gray-06 !default;
$nav-tabs-border-color: $primary !default;
$nav-tabs-border-width: 6px !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $body-color !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $primary !default;
$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

// scss-docs-end nav-variables

@import "./variables/navbar";

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables

@import "./variables/dropdown";

// scss-docs-end dropdown-variables

// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y: 0.375rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-font-size: $font-size-base !default;
$pagination-color: var(--#{$prefix}link-color) !default;
$pagination-bg: $white !default;
$pagination-border-radius: $border-radius !default;
$pagination-border-width: $border-width !default;
$pagination-margin-start: ($pagination-border-width * -1) !default;
$pagination-border-color: $gray-03 !default;
$pagination-focus-color: var(--#{$prefix}link-hover-color) !default;
$pagination-focus-bg: $gray-02 !default;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;
$pagination-hover-color: var(--#{$prefix}link-hover-color) !default;
$pagination-hover-bg: $gray-02 !default;
$pagination-hover-border-color: $gray-03 !default;
$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;
$pagination-disabled-color: $gray-06 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-03 !default;
$pagination-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;
$pagination-border-radius-sm: $border-radius-sm !default;
$pagination-border-radius-lg: $border-radius-lg !default;

// scss-docs-end pagination-variables

// Placeholders

// scss-docs-start placeholders
$placeholder-opacity-max: 0.5 !default;
$placeholder-opacity-min: 0.2 !default;

// scss-docs-end placeholders

// Cards

// scss-docs-start card-variables
$card-spacer-y: $spacer * 1.25 !default;
$card-spacer-x: $spacer * 1.25 !default;
$card-title-spacer-y: $spacer * 0.5 !default;
$card-border-width: 0 !default;
$card-border-color: var(--#{$prefix}border-color-translucent) !default;
$card-border-radius: $border-radius !default;
$card-box-shadow: null !default;
$card-inner-border-radius: subtract(
  $card-border-radius,
  $card-border-width
) !default;
$card-cap-padding-y: $card-spacer-y * 0.5 !default;
$card-cap-padding-x: $card-spacer-x !default;
$card-cap-bg: rgba($black, 0.03) !default;
$card-cap-color: null !default;
$card-height: null !default;
$card-color: null !default;
$card-bg: $white !default;
$card-img-overlay-padding: $spacer !default;
$card-group-margin: $grid-gutter-width * 0.5 !default;

// scss-docs-end card-variables

// Accordion

@import "./variables/accordion";

// Tooltips

@import "./variables/tooltip";

// Form tooltips must come after regular tooltips
// scss-docs-start tooltip-feedback-variables
$form-feedback-tooltip-padding-y: $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x: $tooltip-padding-x !default;
$form-feedback-tooltip-font-size: $tooltip-font-size !default;
$form-feedback-tooltip-line-height: null !default;
$form-feedback-tooltip-opacity: $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;

// scss-docs-end tooltip-feedback-variables

// Popovers

// scss-docs-start popover-variables
$popover-font-size: $font-size-sm !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: var(--#{$prefix}border-color-translucent) !default;
$popover-border-radius: $border-radius-lg !default;
$popover-inner-border-radius: subtract(
  $popover-border-radius,
  $popover-border-width
) !default;
$popover-box-shadow: $box-shadow !default;
$popover-header-font-size: $font-size-base !default;
$popover-header-bg: shade-color($popover-bg, 6%) !default;
$popover-header-color: var(--#{$prefix}heading-color) !default;
$popover-header-padding-y: 0.5rem !default;
$popover-header-padding-x: $spacer !default;
$popover-body-color: $body-color !default;
$popover-body-padding-y: $spacer !default;
$popover-body-padding-x: $spacer !default;
$popover-arrow-width: 1rem !default;
$popover-arrow-height: 0.5rem !default;

// scss-docs-end popover-variables

// fusv-disable
// Deprecated in Bootstrap 5.2.0 for CSS variables
$popover-arrow-color: $popover-bg !default;
$popover-arrow-outer-color: var(--#{$prefix}border-color-translucent) !default;

// fusv-enable

// Toasts

@import "./variables/toasts";

// Badges

// scss-docs-start badge-variables
$badge-font-size: 0.75em !default;
$badge-font-weight: $font-weight-bold !default;
$badge-color: $white !default;
$badge-padding-y: 0.35em !default;
$badge-padding-x: 0.65em !default;
$badge-border-radius: $border-radius !default;

// scss-docs-end badge-variables

// Modals

@import "./variables/modal";

// Alerts

@import "./variables/alert";

// Progress bars

@import "./variables/progress";

// List group

// scss-docs-start list-group-variables
$list-group-color: $gray-08 !default;
$list-group-bg: $white !default;
$list-group-border-color: rgba($black, 0.125) !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;
$list-group-item-padding-y: $spacer * 0.5 !default;
$list-group-item-padding-x: $spacer !default;
$list-group-item-bg-scale: -80% !default;
$list-group-item-color-scale: 40% !default;
$list-group-hover-bg: $gray-01 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;
$list-group-disabled-color: $gray-06 !default;
$list-group-disabled-bg: $list-group-bg !default;
$list-group-action-color: $gray-07 !default;
$list-group-action-hover-color: $list-group-action-color !default;
$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $gray-02 !default;

// scss-docs-end list-group-variables

// Image thumbnails

// scss-docs-start thumbnail-variables
$thumbnail-padding: 0.25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: var(--#{$prefix}border-color) !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: $box-shadow-sm !default;

// scss-docs-end thumbnail-variables

// Figures

// scss-docs-start figure-variables
$figure-caption-font-size: $small-font-size !default;
$figure-caption-color: $gray-06 !default;

// scss-docs-end figure-variables

// Breadcrumbs

// scss-docs-start breadcrumb-variables

@import "./variables/breadcrumb";

// scss-docs-end breadcrumb-variables

// Carousel

// scss-docs-start carousel-variables

@import "./variables/carousel";

// scss-docs-end carousel-variables

// Spinners

// scss-docs-start spinner-variables
$spinner-width: 3rem !default;
$spinner-height: $spinner-width !default;
$spinner-vertical-align: -0.125em !default;
$spinner-border-width: 0.125em !default;
$spinner-animation-speed: 0.75s !default;
$spinner-width-sm: 1.5rem !default;
$spinner-height-sm: $spinner-width-sm !default;
$spinner-border-width-sm: $spinner-border-width !default;

// scss-docs-end spinner-variables

// Close

@import "./variables/close";

// Offcanvas

@import "./variables/offcanvas";

// Code

$code-font-size: $small-font-size !default;
$code-color: $teal-03 !default;
$kbd-padding-y: 0.1875rem !default;
$kbd-padding-x: 0.375rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color: var(--#{$prefix}body-bg) !default;
$kbd-bg: var(--#{$prefix}body-color) !default;
$nested-kbd-font-weight: null !default; // Deprecated in v5.2.0, removing in v6

$pre-color: null !default;
