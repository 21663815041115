@use "sass:map";

.ribbon {
  &__container {
    background-image: linear-gradient(90deg, #0e0f35 0%, #380a62 100%);

    svg {
      margin-top: 0;
    }

    &:hover {
      background-image: linear-gradient(118deg, #380a62 -28.75%, #0e0c83 128.75%);

      svg {
        margin-top: 8px;
      }
    }
  }

  &__container_v2 {
    background-image: linear-gradient(90deg, #031425 0%, #216099 100%);

    svg {
      margin-top: 0;
    }

    &:hover {
      background-image: linear-gradient(118deg, #031425 -28.75%, #216099 128.75%);

      svg {
        margin-top: 8px;
      }
    }
  }

  &__title {
    font-size: 1.25rem !important;
  }
}
