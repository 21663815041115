@import "variables";

:root {
  // Generate variables for all purple rain colors
  @each $color, $value in $purple-rain-colors {
    --#{$ui-library-prefix}#{$color}: #{$value};
  }

  // Generate variables for all the v2 colors
  @each $color, $value in $colors-v2 {
    --#{$ui-library-prefix}#{$color}: #{$value};
  }

  --#{$prefix}primary-gradient: #{$primary-gradient};

  // Export breakpoints to a variable

  // Generate variables for all purple rain breakpoint values
  @each $breakpoint, $value in $grid-breakpoints {
    --#{$ui-library-prefix}breakpoint-#{$breakpoint}: #{$value};
  }

  --font-family-sans-serif: #{$font-family-sans-serif-default};
  --font-family-serif: #{$font-family-serif-default};
  --primary-01: #{$primary-01-default};
  --primary-03: #{$primary-03-default};
  --primary-07: #{$primary-07-default};
  --#{$prefix}heading-font-weight: 400;
}
