.spinner-loader {
  display: block;

  &--dac {
    width: 3rem;
    height: 3rem;

    @include media-breakpoint-up(md) {
      width: 6rem;
      height: 6rem;
    }
  }
}
