@use "sass:map";

.resource-center-hero-image-lakeview-blue {
  object-position: 41% 50%;
  @include media-breakpoint-up(lg) {
    object-position: center;
  }
}

.resource-center-article-container {
  max-width: 664px !important;
  margin: 0 auto;

  @include media-breakpoint-up("md") {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.article-content {
  --#{$prefix}body-color: #{$blue-950} !important;

  h2,
  h3,
  h4 {
    @extend .typeface-h4;

    margin-top: 3rem;
    margin-bottom: 1rem;
    font-weight: bold;

    &:first-of-type {
      margin-top: 0;
    }
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }

    & + h2 {
      &:first-of-type {
        margin-top: 3rem;
      }
    }
  }
}
