@use "sass:map";
@import "../variables/font-family-base";

/************* Font tokens *************/
$font-size-tokens: (
  4xl: clamp(2rem, 5.957vw + -1.131rem, 3.75rem),
  // min 32px, max 60px
  3xl: clamp(1.75rem, 4.255vw + -0.487rem, 3rem),
  // min 28px, max 48px
  2xl: clamp(1.625rem, 2.979vw + 0.059rem, 2.5rem),
  // min 26px, max 40px
  xl: clamp(1.5rem, 1.702vw + 0.605rem, 2rem),
  // min 24px, max 32px
  l: clamp(1.25rem, 0.851vw + 0.803rem, 1.5rem),
  // min 20px, max 24px
  m: 1rem,
  // static 16px
  s: 0.875rem,
  // static 14px
  xs: 0.75rem
  // static 12px
);

/************************************   */
$line-height-tokens: (
  4xl: clamp(2.75rem, 6.383vw + -0.355rem, 4.625rem),
  // min 44px, max 74px
  3xl: clamp(2.5rem, 4.34vw + 0.344rem, 3.75rem),
  // min 40px, max 60px
  2xl: clamp(2rem, 2.766vw + 0.984rem, 3.25rem),
  // min 32px, max 52px
  xl: clamp(1.75rem, 1.191vw + 1.624rem, 2.75rem),
  // min 28px, max 44px
  l: clamp(1.625rem, 0.255vw + 1.741rem, 1.75rem),
  // min 26px, max 28px
  m: 1.5rem,
  // static 24px
  s: 1.125rem,
  // static 18px
  xs: 1rem
  // static 16px
);

/************************************   */
$letter-spacing-tokens: (
  l: 2.1px,
);

/************************************   */

/** The intention is to set font weight using fw-utilities.
  * This is only here as a placeholder
  */
$default-font-weight: 400;
$headings-font-weight: var(--#{$prefix}heading-font-weight);

/** @todo define the curve to calculate the preferred value for clamp()
  * See this article for a starting point:
  * https://allendai1.medium.com/css-clamp-and-writing-a-custom-function-using-sass-246582586a8d
  * @todo set paragraph spacing
  */
.typeface-hxl {
  font-family: $font-family-serif;
  font-size: map.get($font-size-tokens, 4xl);
  font-weight: $default-font-weight;
  line-height: map.get($line-height-tokens, 4xl);
}

.typeface-h1 {
  font-family: $font-family-serif;
  font-size: map.get($font-size-tokens, 3xl);
  font-weight: $headings-font-weight;
  line-height: map.get($line-height-tokens, 3xl);
}

.typeface-h2 {
  font-family: $font-family-serif;
  font-size: map.get($font-size-tokens, 2xl);
  font-weight: $headings-font-weight;
  line-height: map.get($line-height-tokens, 2xl);
}

.typeface-h3 {
  font-family: $font-family-serif;
  font-size: map.get($font-size-tokens, xl);
  font-weight: $headings-font-weight;
  line-height: map.get($line-height-tokens, xl);
}

.typeface-h4 {
  font-family: $font-family-sans-serif;
  font-size: map.get($font-size-tokens, l);
  font-weight: $headings-font-weight;
  line-height: map.get($line-height-tokens, l);
}

.typeface-h5 {
  font-family: $font-family-sans-serif;
  font-size: map.get($font-size-tokens, m);
  font-weight: $headings-font-weight;
  line-height: map.get($line-height-tokens, m);
  letter-spacing: map.get($letter-spacing-tokens, l);
}

.typeface-h6 {
  font-family: $font-family-sans-serif;
  font-size: map.get($font-size-tokens, xs);
  font-weight: $headings-font-weight;
  line-height: map.get($line-height-tokens, xs);
  letter-spacing: map.get($letter-spacing-tokens, l);
}

.typeface-body-large {
  font-family: $font-family-sans-serif;
  font-size: map.get($font-size-tokens, l);
  font-weight: $default-font-weight;
  line-height: map.get($line-height-tokens, l);
}

.typeface-body-regular {
  font-family: $font-family-sans-serif;
  font-size: map.get($font-size-tokens, m);
  font-weight: $default-font-weight;
  line-height: map.get($line-height-tokens, m);
}

.typeface-body-small {
  font-family: $font-family-sans-serif;
  font-size: map.get($font-size-tokens, s);
  font-weight: $default-font-weight;
  line-height: map.get($line-height-tokens, s);
}

.typeface-body-fine {
  font-family: $font-family-sans-serif;
  font-size: map.get($font-size-tokens, xs);
  font-weight: $default-font-weight;
  line-height: map.get($line-height-tokens, xs);
}

.typeface-cta {
  font-family: $font-family-sans-serif;
  font-size: map.get($font-size-tokens, s);
  font-weight: $default-font-weight;
  line-height: map.get($line-height-tokens, s);
  letter-spacing: map.get($letter-spacing-tokens, l);
}

.typeface-cta-small {
  font-family: $font-family-sans-serif;
  font-size: map.get($font-size-tokens, xs);
  font-weight: $default-font-weight;
  line-height: map.get($line-height-tokens, xs);
  letter-spacing: map.get($letter-spacing-tokens, l);
}
