.progress {
  --#{$prefix}progress-height: #{$progress-height-mobile};
  --#{$prefix}progress-font-size: #{$progress-font-size};
  --#{$prefix}progress-bg: #{$progress-bg};
  --#{$prefix}progress-border-radius: #{$progress-border-radius};
  --#{$prefix}progress-box-shadow: #{$progress-box-shadow};
  --#{$prefix}progress-bar-color: #{$progress-bar-color};
  --#{$prefix}progress-bar-bg: #{$progress-bar-bg};
  --#{$prefix}progress-bar-animation-timing: #{$progress-bar-animation-timing};
  --#{$prefix}progress-bar-transition: #{$progress-bar-transition};

  display: flex;
  height: var(--#{$prefix}progress-height);
  overflow: hidden;
  font-size: var(--#{$prefix}progress-font-size);
  background-color: var(--#{$prefix}progress-bg);
  border-radius: var(--#{$prefix}progress-border-radius);
  padding: $progress-padding;

  @include media-breakpoint-up("lg") {
    --#{$prefix}progress-height: #{$progress-height-desktop};
  }
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--#{$prefix}progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--#{$prefix}progress-bar-bg);
  transition: var(--#{$prefix}progress-bar-transition);
  border-radius: $progress-bar-border-radius;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image:
    linear-gradient(
      45deg,
      rgb(255 255 255 / 15%) 25%,
      transparent 25%,
      transparent 50%,
      rgb(255 255 255 / 15%) 50%,
      rgb(255 255 255 / 15%) 75%,
      transparent 75%,
      transparent
    );
  background-size:
    var(--#{$prefix}progress-height)
    var(--#{$prefix}progress-height);
}

.progress-bar-animated {
  animation: var(--#{$prefix}progress-bar-animation-timing) progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
