$sso-rejection-dashboard-img-width-mobile: 270px;
$sso-rejection-dashboard-img-width-desktop: 370px;

.sso-rejection-dashboard-img {
  width: $sso-rejection-dashboard-img-width-mobile;
  height: auto;

  @include media-breakpoint-up("xl") {
    width: $sso-rejection-dashboard-img-width-desktop;
  }
}
