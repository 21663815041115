// TODO: update this values once the header and footer are built
$footer-mobile-height: 900px;
$footer-desktop-height: 612px;

.digital-account-creation {
  position: relative;

  &__full-height {
    min-height: calc(100vh - $navbar-height - $footer-mobile-height);

    @include media-breakpoint-up(md) {
      min-height: calc(100vh - $navbar-desktop-height - $footer-desktop-height);
    }
  }
}

.verify-email-ellipsis-loader {
  &::after {
    content: " ";
    white-space: pre-wrap;
    position: absolute;
    animation: loading-ellipsis 1s ease-in-out infinite;
  }
}

.form-section {
  width: 416px;
  margin: auto;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

@keyframes loading-ellipsis {
  0% {
    content: "";
  }

  33% {
    content: ".";
  }

  66% {
    content: "..";
  }

  100% {
    content: "...";
  }
}
