// variables
$insurance-img-path: "../../../images/insurance/";
$insurance-img: "#{$insurance-img-path}insurance-img.jpeg";
$insurance-img2x: "#{$insurance-img-path}insurance-img2x.jpeg";
$gradient-start: #0e1584;
$gradient-end: #005288;
$text-color: #0f2540;

// mixins
@mixin responsive-font($base, $md, $xl) {
  font-size: $base;
  @include media-breakpoint-up(md) {
    font-size: $md;
  }
  @include media-breakpoint-up(xl) {
    font-size: $xl;
  }
}
@mixin responsive-line-height($base, $md, $xl) {
  line-height: $base;
  @include media-breakpoint-up(md) {
    line-height: $md;
  }
  @include media-breakpoint-up(xl) {
    line-height: $xl;
  }
}

@mixin responsive-letter-spacing($base, $md, $xl) {
  letter-spacing: $base;
  @include media-breakpoint-up(md) {
    letter-spacing: $md;
  }
  @include media-breakpoint-up(xl) {
    letter-spacing: $xl;
  }
}

// styles
.insurance-img {
  background: url($insurance-img) no-repeat center 42% / 100%;
  height: 150px;
  width: 100%;
  @include media-breakpoint-up(md) {
    height: 200px;
  }
  @media only screen and (min-resolution: (2dppx)) {
    background-image: url($insurance-img2x);
  }
}

.bg-gradient-insurance {
  background: linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%) !important;
}

.bolt-insurance-title {
  @include responsive-font(28px, 28px, 40px);
  @include responsive-line-height(36.4px, 33.6px, 52px);

  letter-spacing: 0;
}

.bolt-insurance-paragraph {
  @include responsive-font(14px, 16px, 16px);
  @include responsive-line-height(22.4px, 24px, 24px);
  @include responsive-letter-spacing(0, 0.5px,0);
}

.bolt-insurance-cta {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
}

.bolt-insurance-text-color {
  color: $text-color;
}
