@use "sass:map";

.next-steps {
  &__status {
    line-height: 22px !important;

    @include media-breakpoint-up("md") {
      line-height: 24px !important;
    }

    @include media-breakpoint-up("lg") {
      line-height: 26px !important;
    }
  }

  &__button {
    min-width: 245px;

    @include media-breakpoint-up("md") {
      margin-left: 112px !important;
    }

    @include media-breakpoint-up("lg") {
      margin-left: 0 !important;
    }
  }

  &__cash-out-modal {
    @include media-breakpoint-up("md") {
      max-width: 600px !important;
    }
  }
}
