.mortgage-card {
  &__floating-button {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    pointer-events: all;
    z-index: 10;

    @include media-breakpoint-up(md) {
      width: auto;
      right: 32px;
      bottom: 64px;
      justify-content: end;
    }
  }
}
