$feature-list-simple-item-max-width-md: 190px;
$feature-list-simple-item-max-width-xl: 252px;
$feature-list-simple-item-img-mobile: 88px;
$feature-list-simple-item-img-desktop: 96px;

.feature-list-simple-item {
  .feature-list-simple-item-img svg {
    width: $feature-list-simple-item-img-mobile;
    @include media-breakpoint-up(md) {
      width: $feature-list-simple-item-img-desktop;
    }
  }

  @include media-breakpoint-up(md) {
    max-width: $feature-list-simple-item-max-width-md;
  }
  @include media-breakpoint-up(xl) {
    max-width: $feature-list-simple-item-max-width-xl;
  }
}
