.summary-container {
  display: grid;
  grid-template-columns: 1fr;
}

.summary-container-row {
  display: grid;
  gap: 1rem;
  border-top: 1px solid $purple-4;
  border-left: 1px solid $purple-4;
  border-right: 1px solid $purple-4;

  &:first-of-type {
    border-radius: 5px 5px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 5px 5px;
    border-bottom: 1px solid $purple-4;
  }
}

.borrower-details-container {
  grid-template-columns: 7fr 2fr;
}

.property-details-container {
  grid-template-columns: 5fr 4fr;
}
