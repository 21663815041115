@use "sass:color";
@use "sass:map";

//
// Base styles
//

.accordion {
  // scss-docs-start accordion-css-vars
  --#{$prefix}accordion-color: #{$accordion-color};
  --#{$prefix}accordion-body-color: #{$accordion-body-color};
  --#{$prefix}accordion-bg: #{$accordion-bg};
  --#{$prefix}accordion-transition: #{$accordion-transition};
  --#{$prefix}accordion-border-color: #{$accordion-border-color};
  --#{$prefix}accordion-border-width: #{$accordion-border-width};
  --#{$prefix}accordion-border-radius: #{$accordion-border-radius};
  --#{$prefix}accordion-inner-border-radius: #{$accordion-inner-border-radius};
  --#{$prefix}accordion-btn-padding-x: #{$accordion-button-padding-x};
  --#{$prefix}accordion-btn-padding-y: #{$accordion-button-padding-y};
  --#{$prefix}accordion-btn-color: #{$accordion-color};
  --#{$prefix}accordion-btn-bg: #{$accordion-button-bg};
  --#{$prefix}accordion-btn-icon: #{$accordion-button-icon};
  --#{$prefix}accordion-btn-icon-color: #{$accordion-icon-color};
  --#{$prefix}accordion-btn-icon-width: #{$accordion-icon-width};
  --#{$prefix}accordion-btn-icon-transform: #{$accordion-icon-transform};
  --#{$prefix}accordion-btn-icon-transition: #{$accordion-icon-transition};
  --#{$prefix}accordion-btn-active-icon: #{$accordion-button-active-icon};
  --#{$prefix}accordion-btn-focus-border-color: #{$accordion-button-focus-border-color};
  --#{$prefix}accordion-btn-focus-box-shadow: #{$accordion-button-focus-box-shadow};
  --#{$prefix}accordion-body-padding-x: #{$accordion-body-padding-x};
  --#{$prefix}accordion-body-padding-y: #{$accordion-body-padding-y};
  --#{$prefix}accordion-active-color: #{$accordion-button-active-color};
  --#{$prefix}accordion-active-bg: #{$accordion-button-active-bg};
  --#{$prefix}accordion-btn-font-weight: #{$accordion-button-font-weight};
  --#{$prefix}accordion-btn-active-font-weight: #{$accordion-button-active-font-weight};

  // scss-docs-end accordion-css-vars
}

.accordion-secondary {
  --#{$prefix}accordion-btn-padding-x: 0px;
  --#{$prefix}accordion-btn-padding-y: 0px;
  --#{$prefix}accordion-border-color: $tan-300;

  border-radius: 8px;
  border: 1px $tan-300 solid;

  .accordion-item {
    &:not(:last-of-type) {
      border-bottom: 1px $tan-300 solid;
    }

    &:last-of-type {
      border-bottom: 0;
    }

    .accordion-header {
      .accordion-button {
        // Accordion icon
        &::after {
          margin-right: 0;
          padding: 12px;
          align-self: baseline;
        }
      }
    }

    .accordion-body {
      padding: 0;
    }
  }
}

.accordion-no-divider {
  .accordion-item {
    border-bottom: none;
  }
}

.accordion-button {
  @extend .typeface-body-regular;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  padding: var(--#{$prefix}accordion-btn-padding-y) var(--#{$prefix}accordion-btn-padding-x);
  font-weight: var(--#{$prefix}accordion-btn-font-weight) !important;
  color: var(--#{$prefix}accordion-btn-color);
  text-align: left; // Reset button style
  background-color: var(--#{$prefix}accordion-btn-bg);
  border: 0;
  @include border-radius(0);

  overflow-anchor: none;
  @include transition(var(--#{$prefix}accordion-transition));

  &:not(.collapsed) {
    color: var(--#{$prefix}accordion-active-color);
    background-color: var(--#{$prefix}accordion-active-bg);
    box-shadow: var(--#{$prefix}accordion-btn-focus-box-shadow);
    font-weight: var(--#{$prefix}accordion-btn-active-font-weight);

    &::after {
      background-color: var(--#{$prefix}accordion-btn-icon-color);
      mask-image: var(--#{$prefix}accordion-btn-active-icon);
      transform: var(--#{$prefix}accordion-btn-icon-transform);
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: var(--#{$prefix}accordion-btn-icon-width);
    height: var(--#{$prefix}accordion-btn-icon-width);
    margin-left: 1rem;
    margin-right: 0.5rem;
    content: "";
    background-color: var(--#{$prefix}accordion-btn-icon-color);
    mask-image: var(--#{$prefix}accordion-btn-icon);
    mask-repeat: no-repeat;
    mask-size: var(--#{$prefix}accordion-btn-icon-width);
    mask-position: 50%;

    @include transition(var(--#{$prefix}accordion-btn-icon-transition));
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    border-color: var(--#{$prefix}accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--#{$prefix}accordion-btn-focus-box-shadow);
  }
}

.accordion-header-not-button {
  button {
    &::after {
      width: 0;
    }
  }
}

.accordion-reverse {
  --#{$prefix}accordion-btn-icon-gap: 1rem;

  & .accordion-button {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: var(--#{$prefix}accordion-btn-icon-gap);
  }

  // calculate total distance from icon to header
  // icon width + button gap + 1rem + 0.5rem from left and right margin from icon

  & .accordion-body {
    padding-left:
      calc(
        var(--#{$prefix}accordion-btn-icon-width) +
        calc(var(--#{$prefix}accordion-btn-icon-gap) + 1.5rem)
      );
    padding-top: 0;
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--#{$prefix}accordion-color);
  background-color: var(--#{$prefix}accordion-bg);
  border-bottom: var(--#{$prefix}accordion-border-width) solid var(--#{$prefix}accordion-border-color);

  &:first-of-type {
    @include border-top-radius(var(--#{$prefix}accordion-border-radius));

    .accordion-button {
      @include border-top-radius(
        var(--#{$prefix}accordion-inner-border-radius)
      );
    }
  }

  &:not(:first-of-type) {
    border-top: 0;
  }

  // Only set a border-radius on the last item if the accordion is collapsed
  &:last-of-type {
    .accordion-button {
      &.collapsed {
        @include border-bottom-radius(
          var(--#{$prefix}accordion-inner-border-radius)
        );
      }
    }

    .accordion-collapse {
      @include border-bottom-radius(var(--#{$prefix}accordion-border-radius));
    }
  }
}

.accordion-body {
  width: calc(100% - 40px);
  padding: var(--#{$prefix}accordion-body-padding-y) var(--#{$prefix}accordion-body-padding-x);
  padding-top: 0;
  color: var(--#{$prefix}accordion-body-color);
}

// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
  .accordion-collapse {
    border-width: 0;
  }

  .accordion-item {
    border-right: 0;
    border-left: 0;
    @include border-radius(0);

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    .accordion-button {
      @include border-radius(0);
    }
  }
}

// Card accordion styles overrides
.accordion-card {
  --#{$prefix}accordion-active-color: #{$accordion-color};
  --#{$prefix}accordion-border-radius: #{$accordion-card-border-radius};
  --#{$prefix}accordion-inner-border-radius: #{$accordion-card-inner-border-radius};
  --#{$prefix}accordion-border-width: #{$accordion-card-border-width};
  --#{$prefix}accordion-btn-padding-x: #{$accordion-card-padding-x};
  --#{$prefix}accordion-body-padding-x: #{$accordion-card-padding-x};
  --#{$prefix}accordion-btn-font-weight: #{$accordion-card-button-font-weight};
}

.accordion-button-secondary {
  --#{$prefix}accordion-btn-icon-color: #{$accordion-icon-secondary-color};
}

.accordion-button-primary {
  --#{$prefix}accordion-btn-icon-color: #{$accordion-icon-primary-color};
}

.accordion-triangle {
  --#{$prefix}accordion-btn-icon: #{$accordion-triangle-icon};
  --#{$prefix}accordion-btn-active-icon: #{$accordion-triangle-active-icon};
  --#{$prefix}accordion-btn-icon-width: #{$accordion-triangle-icon-width};
  --#{$prefix}accordion-btn-icon-color: #{$accordion-triangle-icon-color};
}

.accordion-alternate-icon {
  --#{$prefix}accordion-btn-icon: #{$accordion-alternate-icon};
  --#{$prefix}accordion-btn-active-icon: #{$accordion-alternate-active-icon};
  --#{$prefix}accordion-btn-icon-width: #{$accordion-alternate-icon-width};
  --#{$prefix}accordion-btn-icon-color: #{$accordion-alternate-icon-color};
}

.accordion-header-border-bottom {
  border-bottom: 2px solid $white;
  margin-bottom: -2px;
}
