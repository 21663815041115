.card-article {
  --#{$prefix}card-inner-border-radius: #{$border-radius-lg};

  .card-img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 10rem;
  }
}
