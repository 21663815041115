@mixin arrow-down {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  transition: border 0.15s ease-in-out;
  pointer-events: none;
}

@mixin arrow-up {
  border-top: 0;
  border-bottom: 0.3em solid;
}
