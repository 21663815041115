$account-created-modal-sm: 343px;
$account-created-modal-md: 512px;
$account-created-modal-xl: 793px;
$terms-modal-md: 750px;
$terms-modal-xl: 793px;
$terms-decline-confirmation-modal-sm: 512px;
$terms-decline-confirmation-modal: 622px;
$terms-height: 100%;
$afba-content-height: 350px;

.account-created-modal-dimension {
  max-width: $account-created-modal-sm !important;

  @include media-breakpoint-up("md") {
    max-width: $account-created-modal-md !important;
  }

  @include media-breakpoint-up("xl") {
    max-width: $account-created-modal-xl !important;
  }
}

.terms-decline-conformation-modal {
  @include media-breakpoint-up("sm") {
    max-width: $terms-decline-confirmation-modal-sm !important;
  }

  @include media-breakpoint-up("md") {
    max-width: $terms-decline-confirmation-modal !important;
  }
}

.terms-modal-dialog {
  min-height: 100%;
  @include media-breakpoint-up("md") {
    max-width: $terms-modal-md !important;
  }

  @include media-breakpoint-up("xl") {
    max-width: $terms-modal-xl !important;
  }
}

.terms-accordion {
  height: $terms-height;
  overflow: visible;
}

.afba-content {
  overflow-x: hidden;
  scrollbar-gutter: stable;
  height: 100%;

  @include media-breakpoint-up("md") {
    height: $afba-content-height;
  }
}
