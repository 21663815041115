@use "sass:map";

$sign-up-stepper-circle: 1.25rem;
$sign-up-stepper-line-height: 0.25rem;

// To ensure a smooth transition between lg and desktop, and to limit the width
// of the container in desktop resolutions, we use the lg max width as the desktop max width limit
$sign-up-container-max-width: map.get($container-max-widths, lg);

.sign-up-stepper-circle {
  width: $sign-up-stepper-circle;
  height: $sign-up-stepper-circle;
  z-index: 1;
}

.sign-up-stepper-line {
  height: $sign-up-stepper-line-height;
  z-index: 0;
}

.sign-up-form-banner-bg {
  z-index: 0;
}

.sign-up-form-banner-content {
  z-index: 1;
}

// The sign up container is not fullwidth on desktop environments
@include media-breakpoint-up(xl) {
  .sign-up-container {
    max-width: $sign-up-container-max-width;
  }
}
