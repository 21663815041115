@use "sass:map";
@import "./typography";

// scss-docs-start btn-ghost-lite-variables

$btn-ghost-lite-color: $primary-08;
$btn-ghost-primary-disabled-color: map.get(
  $purple-rain-colors,
  "typography-surface-disabled"
);
$btn-ghost-lite-bg-color: transparent;
$btn-ghost-lite-hover-color: $primary-09;
$btn-ghost-lite-active-color: $primary-10;
$btn-ghost-lite-focus-color: $primary-08;
$btn-ghost-lite-disabled-color: $muted-blue-450;

// scss-docs-end btn-ghost-lite-variables

.btn.btn-ghost-lite {
  @include text-cta-lite-mobile-styles;

  border: none;
  border-bottom: 1px solid $btn-ghost-lite-color;
  box-shadow: none;
  color: $btn-ghost-lite-color;
  background-color: $btn-ghost-lite-bg-color;
  border-radius: 0;

  &:hover {
    box-shadow: none;
    text-decoration: none;
    color: $btn-ghost-lite-hover-color;
    border-color: $btn-ghost-lite-hover-color;
  }

  &:focus,
  &.focus {
    color: $btn-ghost-lite-focus-color;
    position: relative;
    border-color: $btn-ghost-lite-focus-color;

    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 4px);
      height: 100%;
      top: 2px;
      left: -2px;
      outline: 2px solid $btn-ghost-lite-focus-color;
      border-radius: 4px;
    }
  }

  &:active,
  &.active {
    color: $btn-ghost-lite-active-color;
    border-color: $btn-ghost-lite-active-color;
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    color: $btn-ghost-lite-disabled-color;
  }

  svg {
    stroke: $btn-ghost-lite-color;
  }

  *,
  *::before,
  *::after {
    @include text-cta-lite-mobile-styles;
  }
}
