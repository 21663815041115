@import "../mixins/form_check";

//
// Check/radio
//

.form-check-label {
  @extend .typeface-body-regular;

  padding-left: 0.15rem;
  color: $form-check-label-color;
  cursor: $form-check-label-cursor;

  &.label-small {
    @extend .typeface-body-small;
  }

  &:hover {
    cursor: pointer;
  }
}

.form-check-input {
  width: $form-check-input-width;
  height: $form-check-input-width;
  margin-top: ($line-height-base - $form-check-input-width) * 0.5; // line-height minus check height
  vertical-align: top;
  background-color: $form-check-input-bg;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: $form-check-input-border;
  appearance: none;
  print-color-adjust: exact; // Keep themed appearance for print
  @include transition($form-check-transition);

  &[type="checkbox"] {
    @include border-radius($form-check-input-border-radius);
  }

  &[type="radio"] {
    border-radius: $form-check-radio-border-radius;
  }

  &:hover {
    cursor: pointer;
    background-color: $form-check-input-hover-bg-color;
    border-color: $form-check-input-hover-border-color;
    transition: $form-check-input-focus-transition;
  }

  &:focus {
    border-color: $form-check-input-focus-border;
    outline: 0;
    box-shadow: $form-check-input-focus-box-shadow;
  }

  &[type="checkbox"]:indeterminate {
    background-color: $form-check-input-indeterminate-bg-color;
    border-color: $form-check-input-indeterminate-border-color;

    @if $enable-gradients {
      background-image:
        escape-svg($form-check-input-indeterminate-bg-image),
        var(--#{$prefix}gradient);
    }
    @else {
      background-image: escape-svg($form-check-input-indeterminate-bg-image);
    }
  }

  .was-validated &:invalid,
  &.is-invalid {
    border-color: $form-switch-invalid-color !important;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      border-color: $form-switch-invalid-color;
    }

    ~ .form-check-label {
      color: $form-check-label-color;
    }
  }

  &:checked {
    border-color: $form-check-input-checked-border-color;

    &[type="checkbox"] {
      background-color: $form-check-input-checked-bg-color;
      background-position: 0.125rem 0.31rem;

      @if $enable-gradients {
        background-image:
          escape-svg(
            background-image-checkbox($form-check-input-checkbox-check-color)
          ),
          var(--#{$prefix}gradient);
      }
      @else {
        background-image:
          escape-svg(
            background-image-checkbox($form-check-input-checkbox-check-color)
          );
      }

      .was-validated &:invalid,
      &.is-invalid {
        border-color: $form-switch-invalid-color;
        background-color: $form-switch-invalid-color;

        &:focus {
          box-shadow: none;
        }
      }

      &[disabled],
      &:disabled {
        border-color: $form-check-input-disabled-border-color;
        background-color: $form-check-input-disabled-color;

        @if $enable-gradients {
          background-image:
            escape-svg(
              background-image-checkbox(
                $form-check-input-checked-disabled-color
              )
            ),
            var(--#{$prefix}gradient);
        }
        @else {
          background-image:
            escape-svg(
              background-image-checkbox($form-check-input-checked-disabled-color)
            );
        }
      }
    }

    &[type="radio"] {
      background-color: $form-check-input-bg;

      @if $enable-gradients {
        background-image:
          escape-svg(
            background-image-radio(
              $form-check-input-radio-check-background-color
            )
          ),
          var(--#{$prefix}gradient);
      }
      @else {
        background-image:
          escape-svg(
            background-image-radio($form-check-input-radio-check-background-color)
          );
      }

      &[disabled],
      &:disabled {
        @if $enable-gradients {
          background-image:
            escape-svg(
              background-image-radio($form-check-input-disabled-color)
            ),
            var(--#{$prefix}gradient);
        }
        @else {
          background-image:
            escape-svg(
              background-image-radio($form-check-input-disabled-color)
            );
        }
      }

      .was-validated &:invalid,
      &.is-invalid {
        border-color: $form-switch-invalid-color;

        @if $enable-gradients {
          background-image:
            escape-svg(
              background-image-radio($form-switch-invalid-color)
            ),
            var(--#{$prefix}gradient);
        }
        @else {
          background-image:
            escape-svg(
              background-image-radio($form-switch-invalid-color)
            );
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  // See: https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: $form-check-input-disabled-opacity;
    border-color: $form-check-input-disabled-color;

    &:hover {
      background-color: $form-check-input-bg;
    }

    ~ .form-check-label {
      cursor: default;
      opacity: $form-check-label-disabled-opacity;
      color: $form-check-input-disabled-color;
    }
  }
}

//
// Switch
//

.form-switch {
  padding-left: $form-switch-padding-start;

  .form-check-input {
    width: $form-switch-width;
    height: $form-switch-height;
    margin-left: $form-switch-padding-start * -1;
    background-color: $form-switch-background-color !important;
    margin-top: 0.3rem;
    margin-right: 0.5rem;
    background-image: none;
    border-width: 0;
    @include border-radius($form-switch-border-radius);

    // circle shadow effect for hover/focus/active states
    &::before {
      content: "";
      position: absolute;
      box-shadow: $form-switch-before-box-shadow;
      border-radius: $form-switch-before-border-radius;
      width: $form-switch-before-width;
      height: $form-switch-before-height;
      background-color: transparent;
      pointer-events: none;
      transform: $form-switch-before-transform;
    }

    &::after {
      content: "";
      position: absolute;
      border: none;
      z-index: 2;
      border-radius: $form-switch-after-border-radius;
      width: $form-switch-after-width;
      height: $form-switch-after-height;
      background-color: $form-switch-after-background-color;
      margin-top: $form-switch-after-margin-top;
      box-shadow: $form-switch-after-box-shadow;
      transition: $form-switch-after-transition;
    }

    &:hover {
      &::before {
        box-shadow: $form-switch-hover-before-box-shadow;
        transform: $form-switch-focus-before-transform;
        transition: $form-switch-before-transition;
      }
    }

    &:active {
      filter: none;

      &::before {
        box-shadow: $form-switch-active-before-box-shadow;
        transform: $form-switch-focus-before-transform;
        transition: $form-switch-before-transition;
      }
    }

    &:focus {
      background-image: none;

      &::before {
        box-shadow: $form-switch-focus-before-box-shadow;
        transform: $form-switch-focus-before-transform;
        transition: $form-switch-before-transition;
      }
    }

    &[disabled],
    &:disabled {
      background-color: $form-switch-background-color !important;

      &::after {
        background-color: $gray-04;
      }
    }

    &:checked {
      background-image: none;
      background-color: $form-switch-checked-background-color !important;

      &::before {
        margin-left: $form-switch-checked-before-margin-left;
        transform: $form-switch-checked-before-transform;
        transition: $form-switch-checked-before-transition;
      }

      &:hover {
        &::before {
          box-shadow: $form-switch-checked-hover-before-box-shadow;
        }
      }

      &:active {
        &::before {
          box-shadow: $form-switch-checked-active-before-box-shadow;
        }
      }

      &:focus {
        background-image: none;

        &::before {
          box-shadow: $form-switch-checked-focus-before-box-shadow;
        }
      }

      &[type="checkbox"] {
        background-image: none;

        &::after {
          content: "";
          position: absolute;
          border: none;
          z-index: 2;
          background-color: $form-switch-checked-checkbox-after-background-color;
          margin-top: $form-switch-checked-checkbox-after-margin-top;
          margin-left: $form-switch-checked-checkbox-after-margin-left;
          box-shadow: $form-switch-checked-checkbox-after-box-shadow;
          transition: $form-switch-checked-checkbox-after-transition;
        }

        &[disabled],
        &:disabled {
          background-color: $form-switch-background-color !important;

          &::after {
            background-color: $gray-04;
          }
        }
      }
    }
  }

  &.form-check-reverse {
    padding-right: $form-switch-padding-start;
    padding-left: 0;

    .form-check-input {
      margin-right: $form-switch-padding-start * -1;
      margin-left: 0;
    }
  }

  &.form-switch-secondary {
    .form-check-input {
      &:checked {
        background-color: $form-switch-secondary-checked-bg-color !important;

        &:hover {
          &::before {
            box-shadow: $form-switch-secondary-checked-hover-before-box-shadow;
          }
        }

        &:active {
          &::before {
            box-shadow: $form-switch-secondary-checked-active-before-box-shadow;
          }
        }

        &:focus {
          &::before {
            box-shadow: $form-switch-secondary-checked-focus-before-box-shadow;
          }
        }

        &[type="checkbox"] {
          background-image: none;

          &::after {
            background-color: $form-switch-secondary-checked-checkbox-after-background-color;
          }

          &[disabled],
          &:disabled {
            background-color: $form-switch-background-color !important;

            &::after {
              background-color: $gray-04;
            }
          }
        }
      }
    }
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: $form-check-inline-margin-end;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &[disabled],
  &:disabled {
    + .btn {
      pointer-events: none;
      filter: none;
      opacity: $form-check-btn-check-disabled-opacity;
    }
  }
}

.form-check-centered {
  gap: 0.25rem 0.75rem;

  :nth-child(2) {
    flex: 1;
  }
}

.form-check-input.center {
  flex: 0 0 auto;
  margin: 0;
  float: none;
}
