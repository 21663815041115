@use "sass:map";

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// Button font configuration
$btn-font-family: $font-family-sans-serif;
$btn-font-size: $button-font-size;
$btn-line-height: $button-line-height;
$btn-font-weight: $button-font-weight;
$btn-letter-spacing: $button-letter-spacing;

// Border radius configuration
$btn-border-radius: $border-radius4;
$btn-border-radius-sm: $border-radius4;
$btn-border-radius-lg: $border-radius4;

// Sizes variables

// Large
$btn-padding-y-lg: 1.0938rem; // 17.5px
$btn-padding-x-lg: 1.5rem; // 24px
$btn-font-size-lg: $btn-font-size;
$btn-line-height-lg: $btn-line-height;

// Medium

$btn-padding-y: 0.75rem;  // 12px
$btn-padding-x: 1.25rem;  // 20px

// Small
$btn-padding-y-sm: 0.375rem;  // 6px
$btn-padding-x-sm: 0.75rem; // 12px
$btn-font-size-sm: $btn-font-size;
$btn-line-height-sm: $btn-line-height;

/*** Variants ***/

// Solid

$btn-solid-hover-opacity: 0.85;

// Primary
$btn-solid-primary-color: map.get(
  $purple-rain-colors,
  "typography-dark-background-high-emphasis"
);
$btn-solid-primary-disabled-color: map.get(
  $purple-rain-colors,
  "typography-dark-background-high-emphasis"
);
$btn-solid-primary-bg-color: var(--primary-07);
$btn-solid-primary-hover-bg-color: $primary-08;
$btn-solid-primary-active-bg-color: $primary-08;
$btn-solid-primary-focus-bg-color: $primary-08;
$btn-solid-primary-disabled-bg-color: $muted-blue-450;

// Secondary
$btn-solid-secondary-color: map.get(
  $purple-rain-colors,
  "typography-surface-medium-emphasis"
);
$btn-solid-secondary-disabled-color: map.get(
  $purple-rain-colors,
  "typography-dark-background-medium-emphasis"
);
$btn-solid-secondary-bg-color: $secondary-06;
$btn-solid-secondary-hover-bg-color: $secondary-06;
$btn-solid-secondary-active-bg-color: $secondary-07;
$btn-solid-secondary-focus-bg-color: $secondary-06;
$btn-solid-secondary-disabled-bg-color: map.get(
  $purple-rain-colors,
  "typography-surface-disabled"
);

// Ghost

// Primary
$btn-ghost-primary-color: $white;
$btn-ghost-primary-disabled-color: map.get(
  $purple-rain-colors,
  "typography-surface-disabled"
);
$btn-ghost-primary-bg-color: $white;
$btn-ghost-primary-hover-bg-color: $primary-01;
$btn-ghost-primary-hover-color: $primary-05;
$btn-ghost-primary-active-bg-color: $primary-02;
$btn-ghost-primary-focus-bg-color: $primary-02;
$btn-ghost-primary-disabled-bg-color: $white;

// Secondary
$btn-ghost-secondary-color: map.get(
  $purple-rain-colors,
  "typography-surface-medium-emphasis"
);
$btn-ghost-secondary-disabled-color: map.get(
  $purple-rain-colors,
  "typography-surface-disabled"
);
$btn-ghost-secondary-bg-color: $white;
$btn-ghost-secondary-hover-bg-color: $secondary-01;
$btn-ghost-secondary-active-bg-color: $secondary-02;
$btn-ghost-secondary-focus-bg-color: $secondary-02;
$btn-ghost-secondary-disabled-bg-color: $white;

// Outline

$btn-outline-border-width: 1px;

// Primary
$btn-outline-primary-color: $primary-06;
$btn-outline-primary-disabled-color: $muted-blue-450;
$btn-outline-primary-bg-color: $white;
$btn-outline-primary-hover-bg-color: $primary-01;
$btn-outline-primary-active-bg-color: $primary-02;
$btn-outline-primary-focus-bg-color: $primary-02;
$btn-outline-primary-disabled-bg-color: $white;
$btn-outline-primary-hover-color: $primary-05;
$btn-outline-primary-border-color: $primary-06;
$btn-outline-primary-disabled-border-color: $muted-blue-450;

// Secondary
$btn-outline-secondary-color: map.get(
  $purple-rain-colors,
  "typography-surface-medium-emphasis"
);
$btn-outline-secondary-disabled-color: map.get(
  $purple-rain-colors,
  "typography-surface-disabled"
);
$btn-outline-secondary-bg-color: $white;
$btn-outline-secondary-hover-bg-color: $secondary-01;
$btn-outline-secondary-active-bg-color: $secondary-02;
$btn-outline-secondary-focus-bg-color: $secondary-02;
$btn-outline-secondary-disabled-bg-color: $white;
$btn-outline-secondary-hover-border-color: $secondary-07;
$btn-outline-secondary-border-color: $secondary-07;
$btn-outline-secondary-disabled-border-color: map.get(
  $purple-rain-colors,
  "typography-surface-disabled"
);

// scss-docs-start btn-variables
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-border-width: 1px;
$btn-box-shadow: none;
$btn-focus-width: 0;
$btn-focus-box-shadow: $elevation-02;
$btn-disabled-opacity: 1;
$btn-active-box-shadow: none;
$btn-link-color: var(--#{$prefix}link-color) !default;
$btn-link-hover-color: var(--#{$prefix}link-hover-color) !default;
$btn-link-disabled-color: $gray-06 !default;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
$btn-hover-bg-shade-amount: 15% !default;
$btn-hover-bg-tint-amount: 15% !default;
$btn-hover-border-shade-amount: 20% !default;
$btn-hover-border-tint-amount: 10% !default;
$btn-active-bg-shade-amount: 20% !default;
$btn-active-bg-tint-amount: 20% !default;
$btn-active-border-shade-amount: 25% !default;
$btn-active-border-tint-amount: 10% !default;

// scss-docs-end btn-variables
