$dropdown-width: 100% !default;
$dropdown-menu-max-height: 200px;
$dropdown-padding-x: 0.5rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-font-color: $black;
$dropdown-height: add(3.5rem, $input-height-border) !default;
$dropdown-border-style: solid;
$dropdown-color-gray-04: $gray-04;
$dropdown-color-primary-06: $primary-06;
$dropdown-color-error-01: $error-01;
$dropdown-color-disabled: map.get(
  $purple-rain-colors,
  "typography-surface-disabled"
);
$dropdown-assertive-text-color: map.get(
  $purple-rain-colors,
  "typography-dark-background-medium-emphasis"
);

// scss-docs-start dropdown-variables
$dropdown-min-width: 10rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-font-size: $font-size-base !default;
$dropdown-color: $body-color !default;
$dropdown-button-color: $white !default;
$dropdown-bg: $white !default;
$dropdown-border-color: var(--#{$prefix}border-color-translucent) !default;
$dropdown-border-radius: $border-radius !default;
$dropdown-border-width: $border-width !default;
$dropdown-inner-border-radius: subtract(
  $dropdown-border-radius,
  $dropdown-border-width
) !default;
$dropdown-divider-bg: $dropdown-border-color !default;
$dropdown-divider-margin-y: $spacer * 0.5 !default;
$dropdown-box-shadow: $box-shadow !default;
$dropdown-link-color: $gray-08 !default;
$dropdown-link-hover-color: shade-color($dropdown-link-color, 10%) !default;
$dropdown-link-hover-bg: $gray-02 !default;
$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;
$dropdown-link-disabled-color: $gray-05 !default;
$dropdown-item-padding-y: $spacer * 0.25 !default;
$dropdown-item-padding-x: $spacer !default;
$dropdown-header-color: $gray-06 !default;
$dropdown-header-padding-x: $dropdown-item-padding-x !default;
$dropdown-header-padding-y: $dropdown-padding-y !default;

// fusv-disable
$dropdown-header-padding: $dropdown-header-padding-y $dropdown-header-padding-x !default; // Deprecated in v5.2.0
// fusv-enable
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color: $gray-03 !default;
$dropdown-dark-bg: $gray-08 !default;
$dropdown-dark-border-color: $dropdown-border-color !default;
$dropdown-dark-divider-bg: $dropdown-divider-bg !default;
$dropdown-dark-box-shadow: null !default;
$dropdown-dark-link-color: $dropdown-dark-color !default;
$dropdown-dark-link-hover-color: $white !default;
$dropdown-dark-link-hover-bg: rgba($white, 0.15) !default;
$dropdown-dark-link-active-color: $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: $gray-05 !default;
$dropdown-dark-header-color: $gray-05 !default;

// scss-docs-end dropdown-dark-variables
