$main-hero-breakpoint: "xl";
$hero-content-text-color: $white;
$hero-height: fit-content;
$hero-padding-x: 0;

// scss-docs-start hero-desktop
$hero-container-desktop-max-width: 502px;

// scss-docs-end hero-desktop

// scss-docs-start hero-unauth
$hero-unauth-mobile-padding-y: 6.5rem;
$hero-unauth-desktop-padding-y: 4rem;

// scss-docs-end hero-unauth

// scss-docs-end hero-auth

// scss-docs-start resource-center

$resource-center-image: url("../../../images/hero/nathan-dumlao-splash-art.png");
$resource-center-image-2x: url("../../../images/hero/nathan-dumlao-splash-art@2x.png");

// scss-docs-end resource-center

.hero {
  background-color: $primary-01;
  min-height: $hero-height;
  height: $hero-height;
  display: flex;
  flex-direction: column;

  .hero-btn {
    @include button-size(
      $btn-padding-y,
      $btn-padding-x-sm,
      $btn-font-size-sm,
      $btn-border-radius
    );

    @include media-breakpoint-up($main-hero-breakpoint) {
      @include button-size(
        $btn-padding-y-lg,
        $btn-padding-x-lg,
        $btn-font-size-lg,
        $btn-border-radius
      );
    }
  }

  .hero-pdp-img {
    width: 270px;
    @include media-breakpoint-up("xl") {
      width: 370px;
    }
  }
}

.hero-unauth {
  background-image: $bg-gradient;
  background-position: 50%;
  color: $hero-content-text-color;
  justify-content: center;
  align-items: flex-start;
  padding: $hero-unauth-mobile-padding-y $hero-padding-x;
  background-color: var(--primary-07);

  @include media-breakpoint-up($main-hero-breakpoint) {
    padding: $hero-unauth-desktop-padding-y $hero-padding-x;
    align-items: center;
  }

  .hero-unauth-title {
    @include media-breakpoint-up($main-hero-breakpoint) {
      max-width: $hero-container-desktop-max-width;
    }
  }
}

.resource-center-hero {
  height: 222px;
  width: 270px;
  background-image: $resource-center-image;
  background-size: cover;
  background-repeat: no-repeat;

  @include media-breakpoint-up($main-hero-breakpoint) {
    height: 350px;
    width: 425px;
    background-size: 425px 350px;
  }

  @media (resolution >= 2dppx) {
    background-image: $resource-center-image-2x;
  }
}

.hero-image-wrapper {
  @include media-breakpoint-up($main-hero-breakpoint) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
