@use "sass:map";

.form {
  .form-title {
    font-size: $h5-font-size;

    @include media-breakpoint-up("lg") {
      font-size: $h4-font-size;
    }
  }

  .form-btn {
    @include button-size(
      $btn-padding-y-sm,
      $btn-padding-x-sm,
      $btn-font-size-sm,
      $btn-border-radius
    );

    text-shadow: none;
    width: 100%;

    @include media-breakpoint-up("md") {
      @include button-size(
        $btn-padding-y,
        $btn-padding-x-lg,
        $btn-font-size-lg,
        $btn-border-radius
      );

      width: fit-content;
    }
  }

  .form-accordion {
    max-height: calc(100vh - 450px);
    overflow: auto;

    @include media-breakpoint-up("lg") {
      max-height: calc(100vh - 400px);
    }
  }

  .form-error-message {
    color: $gray-07;
  }
}

// Rewrite the is valid form styles
.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
  border-color: $primary-06;
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: $primary-06;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: $black;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: transparent;
}

.was-validated .terms-conditions.form-check-input:valid:checked,
.terms-conditions.form-check-input.is-valid:checked {
  background-color: $primary-06;
}

.was-validated .form-floating {
  .form-control {
    &:invalid,
    &.is-invalid {
      ~ label {
        color: $danger !important;
      }

      color: $danger !important;
    }
  }
}

.form-floating > .form-select {
  .was-validated &:invalid,
  &.is-invalid {
    color: $danger;
  }
}
