@use "sass:map";

.toast {
  --#{$prefix}toast-padding-x: #{$toast-padding-x};
  --#{$prefix}toast-padding-y: #{$toast-padding-y};
  --#{$prefix}toast-spacing: map.get($spacers, 3);
  --#{$prefix}toast-max-width: #{$toast-max-width};
  --#{$prefix}toast-font-size: #{$toast-font-size};
  --#{$prefix}toast-color: none;
  --#{$prefix}toast-bg: #{$toast-background-color};
  --#{$prefix}toast-border-width: #{$toast-border-width};
  --#{$prefix}toast-border-color: var(--#{$prefix}border-color-translucent);
  --#{$prefix}toast-border-radius: #{$toast-border-radius};
  --#{$prefix}toast-box-shadow: #{$toast-box-shadow};
  --#{$prefix}toast-header-color: #{$toast-header-color};
  --#{$prefix}toast-header-bg: #{$toast-header-background-color};
  --#{$prefix}toast-header-border-color: #{$toast-header-border-color};

  width: var(--#{$prefix}toast-max-width);
  max-width: 100%;
  font-size: var(--#{$prefix}toast-font-size);
  color: var(--#{$prefix}toast-color);
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: var(--#{$prefix}toast-border-radius);

  &:not(.show) {
    display: none;
  }
}

.toast.error {
  --#{$prefix}toast-bg: #{$toast-bg-error};
}

.toast.success {
  --#{$prefix}toast-bg: #{$toast-bg-success};
}

.toast.alert {
  --#{$prefix}toast-bg: #{$toast-bg-alert};
}

.toast.info {
  --#{$prefix}toast-bg: #{$toast-bg-info};
}

.toast.showing {
  opacity: 0;
}

.toast-container {
  position: absolute;
  z-index: $zindex-toast;
  width: max-content;
  max-width: 100%;
  pointer-events: none;

  > :not(:last-child) {
    margin-bottom: var(--#{$prefix}toast-spacing);
  }
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--#{$prefix}toast-padding-y) var(--#{$prefix}toast-padding-x);
  color: var(--#{$prefix}toast-header-color);
  background-color: var(--#{$prefix}toast-header-bg);
  background-clip: padding-box;
  border-bottom:
    var(--#{$prefix}toast-border-width) solid
    var(--#{$prefix}toast-header-border-color);
  border-top-left-radius:
    calc(
      var(--#{$prefix}toast-border-radius) - var(--#{$prefix}toast-border-width)
    );
  border-top-right-radius:
    calc(
      var(--#{$prefix}toast-border-radius) - var(--#{$prefix}toast-border-width)
    );
}

.toast-body {
  padding: var(--#{$prefix}toast-padding-x);
  word-wrap: break-word;
  box-shadow: $toast-body-box-shadow, var(--#{$prefix}toast-box-shadow);
  background-color: var(--#{$prefix}toast-bg);
}

.toast-content {
  @extend .typeface-body-regular;

  padding: 0 1rem;
  text-align: left;
}
