// scss-docs-start headline-05-lite-variables
$headline-05-lite-font-family: $font-family-base;
$headline-05-lite-font-size: $font-size-base;
$headline-05-lite-font-weight: $headings-font-weight;
$headline-05-lite-line-height: $headline-05-lite-font-size * 1.4; // 22.4px
$headline-05-lite-letter-spacing: $headline-05-lite-font-size * 0.15; // 2.4px

$headline-05-lite-mobile-font-size: $font-size-sm;
$headline-05-lite-mobile-font-weight: 500;
$headline-05-lite-mobile-line-height: $headline-05-lite-mobile-font-size * 1.4; // 19.6px
$headline-05-lite-mobile-letter-spacing: $headline-05-lite-mobile-font-size * 0.15; // 2.1px

// scss-docs-end headline-05-lite-variables

// scss-docs-start text-cta-lite-variables

$text-cta-lite-mobile-font-size: $caption-font-size; // 12px
$text-cta-lite-mobile-font-weight: 500;
$text-cta-lite-mobile-line-height: $text-cta-lite-mobile-font-size * 1.4; // 16.8px
$text-cta-lite-mobile-letter-spacing: $text-cta-lite-mobile-font-size * 0.15; // 1.8px

$text-cta-lite-desktop-font-size: $font-size-sm; // 14px
$text-cta-lite-desktop-font-weight: 500;
$text-cta-lite-desktop-line-height: $text-cta-lite-desktop-font-size * 1.4; // 19.6px
$text-cta-lite-desktop-letter-spacing: $text-cta-lite-desktop-font-size * 0.15; // 2.1px

// scss-docs-end text-cta-lite-variables

// scss-docs-start text-body-lite-variables

$text-body-lite-font-size: $font-size-sm; // 12px
$text-body-lite-font-weight: $font-weight-normal;
$text-body-lite-line-height: $text-body-lite-font-size * 1.6; // 16.8px

// scss-docs-end text-body-lite-variables

// Styles corresponding to the headline 04 lite
@mixin headline-05-lite-styles {
  font-family: $headline-05-lite-font-family;
  font-size: $headline-05-lite-font-size;
  font-weight: $headline-05-lite-font-weight;
  line-height: $headline-05-lite-line-height;
  letter-spacing: $headline-05-lite-letter-spacing;
}

// Styles corresponding to the headline 04 lite mobile
@mixin headline-05-lite-mobile-styles {
  font-family: $headline-05-lite-font-family;
  font-size: $headline-05-lite-mobile-font-size;
  font-weight: $headline-05-lite-mobile-font-weight;
  line-height: $headline-05-lite-mobile-line-height;
  letter-spacing: $headline-05-lite-mobile-letter-spacing;
}

// Styles corresponding to the text cta lite mobile
@mixin text-cta-lite-mobile-styles {
  font-size: $text-cta-lite-mobile-font-size;
  font-weight: $text-cta-lite-mobile-font-weight;
  line-height: $text-cta-lite-mobile-line-height;
  letter-spacing: $text-cta-lite-mobile-letter-spacing;
}

// Styles corresponding to the text cta lite desktop
@mixin text-cta-lite-desktop-styles {
  font-size: $text-cta-lite-desktop-font-size;
  font-weight: $text-cta-lite-desktop-font-weight;
  line-height: $text-cta-lite-desktop-line-height;
  letter-spacing: $text-cta-lite-desktop-letter-spacing;
}

// Styles corresponding to the text cta body desktop
@mixin text-body-lite-styles {
  font-size: $text-body-lite-font-size;
  font-weight: $text-body-lite-font-weight;
  line-height: $text-body-lite-line-height;
}

.text-headline-05-lite {
  @include headline-05-lite-mobile-styles;

  @include media-breakpoint-up("md") {
    @include headline-05-lite-styles;
  }
}

.text-cta-mobile-lite {
  @include text-cta-lite-mobile-styles;
}

.text-cta-desktop-lite {
  @include text-cta-lite-desktop-styles;
}

.text-body-lite {
  @include text-body-lite-styles;
}
