$font-family-fallback: system-ui,
  -apple-system,
  "Segoe UI",
  roboto,
  "Helvetica Neue",
  "Noto Sans",
  "Liberation Sans",
  arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";
$font-family-sans-serif: var(--font-family-sans-serif);
$font-family-serif: var(--font-family-serif);
