@use "sass:map";

.loan-options-container {
  .card-container {
    min-height: 332px;
  }

  .card-content {
    min-height: 80px;
  }

  .swiper {
    padding-bottom: 45px;
  }

  .swiper-pagination-bullet-active {
    background: $black;
  }
}
