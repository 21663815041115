$progress-height-mobile: 0.5rem !default;
$progress-height-desktop: 0.5rem !default;
$progress-font-size: $font-size-base * 0.75 !default;
$progress-bg: $gray-04 !default;
$progress-border-radius: $border-radius-xl !default;
$progress-box-shadow: $box-shadow-inset !default;
$progress-bar-color: $white !default;
$progress-bar-bg: $secondary-08 !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width 0.6s ease !default;
$progress-padding: 0 !default;
$progress-bar-border-radius: 0.5rem;
