/************* Color definitions *************/
$transparent: transparent;
$white: #fff;
$black: #000;

/************************************   */
$purple-700: #6721a6;

/************************************   */
$blue-900: #050461;
$blue-950: #00074a;

/************************************   */
$muted-blue-450: #8281b0;
$muted-blue-800: #403f6c;

/************************************   */
$dark-muted-blue-800: #0e0f35;

/************************************   */
$tan-300: #e1d8de;

/************* Color maps *************/

$globals: (
  "transparent": $transparent,
  "white": $white,
  "black": $black,
);

/************************************   */

$purples: (
  "purple-700": $purple-700,
);

/************************************   */

$blues: (
  "blue-900": $blue-900,
  "blue-950": $blue-950,
);

/************************************   */

$muted-blues: (
  "muted-blue-450": $muted-blue-450,
  "muted-blue-800": $muted-blue-800,
);

/************************************   */

$dark-muted-blues: (
  "dark-muted-blue-800": $dark-muted-blue-800,
);

/************************************   */

$tans: (
  "tan-300": $tan-300,
);

/************* Color aggregation *************/

$colors-v2: map-collect(
  $globals,
  $purples,
  $blues,
  $muted-blues,
  $dark-muted-blues,
  $tans,
);
