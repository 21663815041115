@use "sass:map";
@import "../variables/font-family-base";

////
/// Typography variables
/// Variables for font family, weights, sizes for the UI library
////

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;

// stylelint-enable value-keyword-case
$font-family-base: $font-family-sans-serif;
$font-family-code: $font-family-monospace;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875; // 14px
$font-size-lg: $font-size-base * 1.25;
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium-bold: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;
$line-height-sm: 1.25rem;
$line-height-lg: 2;
$h1-font-size: $font-size-base * 6; // 96px
$h2-font-size: $font-size-base * 3.75; // 60px
$h3-font-size: $font-size-base * 3; // 48px
$h4-font-size: $font-size-base * 2.125; // 34px
$h5-font-size: $font-size-base * 1.5; // 24px
$h6-font-size: $font-size-base * 1.25; // 20px
$font-size-26px: $font-size-base * 1.625; // 26px
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  "26px": $font-size-26px,
);

// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: $spacer * 0.5;
$headings-font-family: $font-family-serif;
$headings-font-style: null;
$headings-font-weight: var(--#{$prefix}heading-font-weight);
$headings-line-height: 1.2;
$headings-color: null;

// scss-docs-start headline-01-variables
$headline-01-font-family: $headings-font-family;
$headline-01-font-size: map.get($font-sizes, 1);
$headline-01-font-weight: $headings-font-weight;
$headline-01-line-height: 7rem; // 112px

// scss-docs-end headline-01-variables

// scss-docs-start headline-02-variables
$headline-02-font-family: $headings-font-family;
$headline-02-font-size: map.get($font-sizes, 2);
$headline-02-font-weight: $headings-font-weight;
$headline-02-line-height: 4.5rem; // 72px

// scss-docs-end headline-02-variables

// scss-docs-start headline-03-variables
$headline-03-font-family: $headings-font-family;
$headline-03-font-size: map.get($font-sizes, 3);
$headline-03-font-weight: $headings-font-weight;
$headline-03-line-height: 3.5rem; // 56px

// scss-docs-end headline-03-variables

// scss-docs-start headline-04-variables
$headline-04-font-family: $headings-font-family;
$headline-04-font-size: map.get($font-sizes, 4);
$headline-04-font-weight: $headings-font-weight;
$headline-04-line-height: 2.55rem; // 40.8px

// scss-docs-end headline-04-variables

// scss-docs-start headline-05-variables
$headline-05-font-family: $headings-font-family;
$headline-05-font-size: map.get($font-sizes, 5);
$headline-05-font-weight: $headings-font-weight;
$headline-05-line-height: 1.8rem; // 28.8px

// scss-docs-end headline-05-variables

// scss-docs-start headline-06-variables
$headline-06-font-family: $headings-font-family;
$headline-06-font-size: map.get($font-sizes, 6);
$headline-06-font-weight: $headings-font-weight;
$headline-06-line-height: 1.5rem; // 24px

// scss-docs-end headline-06-variables

// scss-docs-start headline-26px-variables
$headline-26px-font-family: $headings-font-family;
$headline-26px-font-size: map.get($font-sizes, "26px");
$headline-26px-font-weight: $headings-font-weight;
$headline-26px-line-height: 1.5rem; // 24px

// scss-docs-end headline-26px-variables

// scss-docs-end headings-variables

// scss-docs-start body-01-variables
// Corresponds to the default body styles
$body-01-font-family: $font-family-sans-serif;
$body-01-font-size: $font-size-base; // 16px
$body-01-font-weight: $font-weight-normal;
$body-01-line-height: 1.5rem; // 24px
$body-01-letter-spacing: 0.5px;

// scss-docs-end body-01-variables

// scss-docs-start body-02-variables
// Body text for smaller spaces
$body-02-font-family: $font-family-sans-serif;
$body-02-font-size: $font-size-sm;
$body-02-font-weight: $font-weight-normal;
$body-02-line-height: $line-height-sm; // 20px
$body-02-letter-spacing: 0.25px;

// scss-docs-end body-02-variables

// scss-docs-start subtitle-01-variables
$subtitle-01-font-family: $font-family-sans-serif;
$subtitle-01-font-size: $font-size-base; // 16px
$subtitle-01-font-weight: $font-weight-bold;
$subtitle-01-line-height: 1.5rem; // 24px
$subtitle-01-letter-spacing: 0.15px;

// scss-docs-end subtitle-01-variables

// scss-docs-start subtitle-02-variables
$subtitle-02-font-family: $font-family-sans-serif;
$subtitle-02-font-size: $font-size-base * 0.875; // 14px
$subtitle-02-font-weight: $font-weight-normal;
$subtitle-02-line-height: 1.5rem; // 24px
$subtitle-02-letter-spacing: 0.1px;

// scss-docs-end subtitle-02-variables

// scss-docs-start eyebrow-variables
$eyebrow-font-family: $font-family-sans-serif;
$eyebrow-font-size: $font-size-base * 0.75; // 12px
$eyebrow-font-weight: $font-weight-bold;
$eyebrow-line-height: 1rem; // 16px
$eyebrow-letter-spacing: 1.5px;

// scss-docs-end eyebrow-variables

// scss-docs-start caption-variables
$caption-font-family: $font-family-sans-serif;
$caption-font-size: $font-size-base * 0.75; // 12px
$caption-font-weight: $font-weight-normal;
$caption-line-height: 1rem; // 16px
$caption-letter-spacing: 0.4px;

// scss-docs-end caption-variables

// scss-docs-start button-font-variables
$button-font-family: $font-family-sans-serif;
$button-font-size: $font-size-base * 0.875; // 14px
$button-font-weight: $font-weight-medium-bold;
$button-line-height: 1.225rem; // 19.6px
$button-letter-spacing: 1.25px;

// scss-docs-end button-font-variables

// scss-docs-start label-font-variables
$label-font-family: $font-family-sans-serif;
$label-font-size: $font-size-base * 0.625; // 10px;
$label-font-weight: $font-weight-bold;
$label-line-height: 1rem; // 16px
$label-letter-spacing: 0.4px;

// scss-docs-end label-font-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 6rem,
  2: 3.75rem,
  3: 3rem,
  4: 2.125rem,
  5: 1.5rem,
  6: 1.25rem,
);
$display-font-family: null;
$display-font-style: null;
$display-font-weight: 400;
$display-line-height: $headings-line-height;

// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 400;
$small-font-size: 0.875em;
$sub-sup-font-size: 0.65em;
$text-muted: $gray-06;
$initialism-font-size: $small-font-size;
$blockquote-margin-y: $spacer;
$blockquote-font-size: $font-size-base * 1.25;
$blockquote-footer-color: $gray-06;
$blockquote-footer-font-size: $small-font-size;
$legend-margin-bottom: 0.5rem !default;
$legend-font-size: 1.5rem !default;
$legend-font-weight: null !default;
$dt-font-weight: $font-weight-bold !default;

// scss-docs-end type-variables
