// scss-docs-start offcanvas-variables
$offcanvas-padding-y: 2.75rem;
$offcanvas-padding-x: 1.5rem;
$offcanvas-horizontal-width: 312px;
$offcanvas-vertical-height: 98vh;
$offcanvas-transition-duration: 0.3s !default;
$offcanvas-border-color: none;
$offcanvas-border-width: $modal-content-border-width !default;
$offcanvas-title-line-height: $modal-title-line-height !default;
$offcanvas-bg-color: $white !default;
$offcanvas-color: $modal-content-color !default;
$offcanvas-box-shadow: $modal-content-box-shadow-xs !default;
$offcanvas-backdrop-bg: $modal-backdrop-bg !default;
$offcanvas-backdrop-opacity: $modal-backdrop-opacity !default;
$offcanvas-bottom-border-radius: 1.5rem;
$offcanvas-padding-bottom: 1.3rem;
$offcanvas-dialog-width-md: 670px;
$offcanvas-dialog-width-lg: 835px;
$offcanvas-dialog-width-xl: 1010px;
$offcanvas-dialog-vertical-height: 65vh;
$offcanvas-dialog-border-radius: 0.875rem;
$offcanvas-dialog-padding-x: 5.375rem;

// scss-docs-end offcanvas-variables
