@use "sass:map";

$form-check-input-width: 1.5rem;
$form-check-min-height: auto;
$form-check-padding-start: $form-check-input-width + 0.5rem !default;
$form-check-margin-bottom: 0;
$form-check-label-color: map.get(
  $purple-rain-colors,
  "typography-surface-high-emphasis"
);
$form-check-label-cursor: null !default;
$form-check-transition: null !default;
$form-check-input-bg: $white;
$form-check-input-border-width: 1px;
$form-check-input-border-color: map.get(
  $purple-rain-colors,
  "typography-surface-medium-emphasis"
);
$form-check-input-border: $form-check-input-border-width solid
  $form-check-input-border-color;
$form-check-input-border-radius: 0.25rem; // 4px
$form-check-radio-border-radius: 50% !default;
$form-check-input-focus-border: $form-check-input-border-color;
$form-check-input-focus-box-shadow: none;
$form-check-input-indeterminate-color: $white;
$form-check-input-indeterminate-bg-color: $primary-06;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;
$form-check-input-disabled-opacity: 1;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity !default;
$form-check-inline-margin-end: 1rem !default;
$form-check-input-hover-bg-color: map.get(
  $purple-rain-colors,
  "state-surface-hover"
);
$form-check-input-hover-border-color: map.get(
  $purple-rain-colors,
  "typography-surface-high-emphasis"
);
$form-check-input-focus-transition: border-color 0.2s;
$form-check-input-checked-color: $white;
$form-check-input-checked-bg-color: $primary-06;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-disabled-border-color: transparent;
$form-check-input-disabled-color: map.get(
  $purple-rain-colors,
  "typography-surface-disabled"
);
$form-check-input-checked-disabled-color: map.get(
  $purple-rain-colors,
  "typography-dark-background-disabled"
);

// == Checkbox ==
$form-check-input-checkbox-check-color: $white;

// == Radio button ==
$form-check-input-radio-check-background-color: $primary-06;

// == Switch ==
// scss-docs-start form-switch-base-variables
$form-switch-width: 2.125rem;
$form-switch-height: 0.875rem;
$form-switch-padding-start: $form-switch-width + 0.5rem;
$form-switch-background-color: $gray-04;
$form-switch-checked-background-color: $primary-03;
$form-switch-border-radius: 0.4375rem;
$form-switch-invalid-color: map.get(
  $purple-rain-colors,
  "notification-error-bold"
);

// scss-docs-end form-switch-base-variables

// scss-docs-start form-switch-before-variables
$form-switch-before-box-shadow: 3px 0 0 13px transparent;
$form-switch-before-border-radius: 50%;
$form-switch-before-width: 0.875rem;
$form-switch-before-height: $form-switch-before-width;
$form-switch-before-transform: scale(0);
$form-switch-focus-before-transform: scale(1);
$form-switch-before-transition: box-shadow 0.2s, transform 0.2s;
$form-switch-checked-before-transform: scale(1);
$form-switch-checked-before-transition: box-shadow 0.2s, transform 0.2s;
$form-switch-checked-before-margin-left: 1.0625rem;
$form-switch-checked-focus-before-transition: box-shadow 0.2s, transform 0.2s;
$form-switch-before-box-shadow-size: 3px 0 0 13px;
$form-switch-hover-before-box-shadow: $form-switch-before-box-shadow-size
  map.get($purple-rain-colors, "state-surface-hover");
$form-switch-active-before-box-shadow: $form-switch-before-box-shadow-size
  map.get($purple-rain-colors, "state-surface-pressed");
$form-switch-focus-before-box-shadow: $form-switch-before-box-shadow-size
  map.get($purple-rain-colors, "state-surface-focus");
$form-switch-checked-hover-before-box-shadow: $form-switch-before-box-shadow-size
  map.get($purple-rain-colors, "state-primary-hover");
$form-switch-checked-active-before-box-shadow: $form-switch-before-box-shadow-size
  map.get($purple-rain-colors, "state-primary-pressed");
$form-switch-checked-focus-before-box-shadow: $form-switch-before-box-shadow-size
  map.get($purple-rain-colors, "state-primary-focus");

// scss-docs-end form-switch-before-variables

// scss-docs-start form-switch-after-variables
$form-switch-after-border-radius: 50%;
$form-switch-after-width: 1.25rem;
$form-switch-after-height: $form-switch-after-width;
$form-switch-after-background-color: $primary-04;
$form-switch-after-margin-top: -0.1875rem;
$form-switch-after-box-shadow: $elevation-02;
$form-switch-after-transition: background-color 0.2s, transform 0.2s;
$form-switch-checked-checkbox-after-background-color: $primary;
$form-switch-checked-checkbox-after-margin-top: -3px;
$form-switch-checked-checkbox-after-margin-left: 1.0625rem;
$form-switch-checked-checkbox-after-box-shadow: $elevation-02;
$form-switch-checked-checkbox-after-transition: background-color 0.2s,
  transform 0.2s !default;

// scss-docs-end form-switch-after-variables

// scss-docs-start form-switch-secondary-variables
$form-switch-secondary-checked-bg-color: $secondary-03;
$form-switch-secondary-checked-checkbox-after-background-color: $secondary-06;
$form-switch-secondary-checked-hover-before-box-shadow: $form-switch-before-box-shadow-size
  map.get($purple-rain-colors, "state-secondary-hover");
$form-switch-secondary-checked-active-before-box-shadow: $form-switch-before-box-shadow-size
  map.get($purple-rain-colors, "state-secondary-pressed");
$form-switch-secondary-checked-focus-before-box-shadow: $form-switch-before-box-shadow-size
  map.get($purple-rain-colors, "state-secondary-focus");

// scss-docs-end form-switch-secondary-variables

// scss-docs-start form-check-variables
$form-check-input-active-filter: brightness(90%) !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color: map.get($purple-rain-colors, "typography-surface-disabled");
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-transition: background-position 0.15s ease-in-out !default;
$form-switch-focus-color: $primary-06 !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;
$form-switch-checked-color: $primary-06 !default;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position: right center !default;

// scss-docs-end form-switch-variables
