.sso-banner {
  animation: sso-banner-dropdown 0.35s ease;
  transition: none;
}

.banner-container {
  @include media-breakpoint-up("xxxl") {
    max-width: 1136px !important;

    & > .gap-100px {
      gap: 100px !important;
    }
  }
}

.password-input-container {
  max-width: 385px;
}

.box-title {
  letter-spacing: 1.6px;
  line-height: 1.2;
}

@keyframes sso-banner-dropdown {
  from {
    max-height: 0;
  }

  to {
    max-height: 1200px;
  }
}
