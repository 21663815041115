@use "sass:map";

// scss-docs-start modal-variables
$modal-inner-padding: $spacer !default;
$modal-footer-margin-between: 1.5rem;
$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;
$modal-title-line-height: $line-height-base !default;
$modal-content-color: null !default;
$modal-content-bg: $white;
$modal-content-border-color: var(--#{$prefix}border-color-translucent) !default;
$modal-content-border-width: 0 !default;
$modal-content-border-radius: $border-radius-lg !default;
$modal-content-inner-border-radius: subtract(
  $modal-content-border-radius,
  $modal-content-border-width
) !default;
$modal-content-box-shadow: $elevation-05 !default;
$modal-content-box-shadow-xs: $box-shadow-sm !default;
$modal-content-box-shadow-sm-up: $box-shadow !default;
$modal-backdrop-bg: $dark-muted-blue-800 !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: var(--#{$prefix}border-color) !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 1rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility
$modal-body-padding-top: 1.5rem;
$modal-footer-bg: null !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-footer-box-shadow: $reverse-elevation-01;
$modal-sm: 300px !default;
$modal-md: 600px !default;
$modal-lg: calc(100% - 3rem) !default;
$modal-xl: 1010px !default;
$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;

// scss-docs-end modal-variables
