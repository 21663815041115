@import "../mixins/dropdown";

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

@include media-breakpoint-up("sm") {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@include media-breakpoint-up("md") {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@include media-breakpoint-up("lg") {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@include media-breakpoint-up("xl") {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@include media-breakpoint-up("xxl") {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu.scrollable {
  max-height: $dropdown-menu-max-height;
  overflow: auto;
}

.dropdown-header {
  display: block;
  padding:
    var(--bs-dropdown-header-padding-y)
    var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #e5e7eb;
  --bs-dropdown-bg: #384250;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: none;
  --bs-dropdown-link-color: #e5e7eb;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgb(255 255 255 / 15%);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #525186;
  --bs-dropdown-link-disabled-color: #9da4ae;
  --bs-dropdown-header-color: #9da4ae;
}

.dropdown {
  position: relative;

  .assertive-text {
    color: $dropdown-assertive-text-color;
  }

  .error-text {
    color: $dropdown-color-error-01;
  }

  .btn {
    &.dropdown-toggle {
      color: $dropdown-button-color;
    }
  }
}

// Arrow - Down
.dropdown-toggle::after {
  @include arrow-down;
}

// Arrow - Up
.dropdown-toggle.show::after {
  @include arrow-down;
}

// Button - Text
.dropdown-btn-text {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem 0.75rem;
  padding-bottom: 0.5rem;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
  border: none;
  opacity: 1;
  pointer-events: none;
  color: $dropdown-font-color;
}

// Button - label
.dropdown-label {
  @extend .typeface-body-regular;

  position: absolute;
  z-index: -1;
  background-color: transparent;
  width: 100%;
  color: $dropdown-font-color;
  top: 0;
  left: 0;
  padding: 1rem 0.75rem;
  transition: padding 0.3s ease;
}

// Button
.dropdown-toggle {
  position: relative;
  height: $dropdown-height;
  white-space: nowrap;

  // border
  border:
    $dropdown-border-width $dropdown-border-style
    $dropdown-color-primary-06;
  border-radius: 0.5rem;

  // flex
  display: flex;
  align-items: center;
  justify-content: space-between;

  // font
  @extend .typeface-body-regular;

  color: $dropdown-font-color;
  text-transform: capitalize;
  width: 100%;

  // Error
  &:disabled.error {
    border:
      $dropdown-border-width $dropdown-border-style
      $dropdown-color-error-01;

    + .dropdown-btn-text {
      opacity: 0;

      + .dropdown-label {
        @extend .typeface-body-regular;

        color: $dropdown-color-error-01;
        top: 0;
        left: 0;
        padding: 1rem 0.75rem;
      }
    }
  }

  // Disable
  &:disabled {
    border:
      $dropdown-border-width $dropdown-border-style
      $dropdown-color-disabled;

    + .dropdown-btn-text {
      opacity: 0;

      + .dropdown-label {
        @extend .typeface-body-regular;

        color: $dropdown-color-disabled;
        top: 0;
        left: 0;
        padding: 1rem 0.75rem;
      }
    }
  }

  &:not(:disabled) {
    &:enabled {
      + .dropdown-btn-text::placeholder {
        opacity: 0;
        color: $dropdown-font-color;
      }
    }

    &:active {
      border:
        $dropdown-border-width $dropdown-border-style
        $dropdown-color-primary-06;
    }

    &:hover {
      border:
        $dropdown-border-width $dropdown-border-style
        $dropdown-color-primary-06;
    }

    &:focus {
      border:
        $dropdown-border-width $dropdown-border-style
        $dropdown-color-primary-06;

      ~ .dropdown-label {
        @extend .typeface-body-small;

        color: $dropdown-color-primary-06;
        padding: 0.5rem 0.75rem;
      }

      + .dropdown-btn-text::placeholder {
        opacity: 1;
      }
    }

    &:not(:focus) + .dropdown-btn-text:not(:invalid) + .dropdown-label {
      @extend .typeface-body-small;

      color: $dropdown-font-color;
      padding: 0.5rem 0.75rem;
    }

    &:not(:focus) + .dropdown-btn-text:placeholder-shown + .dropdown-label {
      @extend .typeface-body-regular;

      color: $dropdown-font-color;
      top: 0;
      left: 0;
      padding: 1rem 0.75rem;
    }
  }
}

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0.5rem;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: $dropdown-font-color;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-radius: 0 0 0.25rem 0.25rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow:
    0 4px 8px -2px rgb(0 0 0 / 10%),
    0 2px 4px -2px rgb(0 0 0 / 6%);
  --bs-dropdown-link-color: #384250;
  --bs-dropdown-link-hover-color: #323b48;
  --bs-dropdown-link-hover-bg: #f3f4f6;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #525186;
  --bs-dropdown-link-disabled-color: #9da4ae;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c737f;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;

  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--bs-dropdown-min-width);
  width: 100%;
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  box-shadow: var(--bs-dropdown-box-shadow);
  border: none;
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item-no-link:hover,
.dropdown-item-no-link:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item-no-link.active,
.dropdown-item-no-link:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled,
.dropdown-item:disabled,
.dropdown-item-no-link.disabled,
.dropdown-item-no-link:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-item-no-link {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  cursor: pointer;
}
