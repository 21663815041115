@use "sass:map";

// scss-docs-start form-floating-variables
$form-floating-height: add(3.5rem, $input-height-border) !default;
$form-floating-line-height: 1.25 !default;
$form-floating-padding-x: $input-padding-x !default;
$form-floating-padding-y: 1rem !default;
$form-floating-input-padding-t: 1.625rem !default;
$form-floating-input-padding-b: 0.625rem !default;
$form-floating-label-opacity: 1 !default;
$form-floating-label-transform: translateY(-0.5rem);
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out !default;
$form-floating-label-focus-color: map.get($purple-rain-colors, "primary-08");
$form-floating-label-color: map.get($purple-rain-colors, "typography-surface-medium-emphasis");
$form-floating-label-disabled-color: $muted-blue-450 !default;

// scss-docs-end form-floating-variables
