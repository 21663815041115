$complete-profile-img-mobile: 4rem;
$complete-profile-img-tablet: 5rem;
$complete-profile-img-desktop: 7.5rem;

.complete-profile-img {
  width: $complete-profile-img-mobile;
  @include media-breakpoint-up(md) {
    width: $complete-profile-img-tablet;
  }
  @include media-breakpoint-up(xl) {
    width: $complete-profile-img-desktop;

    &__show-title {
      margin-top: 1.5rem;
    }
  }
}

.complete-profile-button {
  height: 3.625rem;

  &__without-title {
    @include media-breakpoint-up(xl) {
      margin-top: 3.25rem;
    }
  }

  &__with-title {
    @include media-breakpoint-up(xl) {
      margin-top: 6.5rem;
    }
  }
}
