.was-validated {
  .input-container:has(.input:invalid) {
    .input,
    label {
      @apply tw-text-error tw-border-error;
    }

    .input {
      @apply tw-border-error;
    }

    .has-end-icons {
      padding-right: 68px;
    }

    .error-icon,
    .error-message {
      display: block;
    }
  }

  .input-container:has(.input:valid) {
    .has-end-icons {
      padding-right: 40px;
    }
  }
}
