$product-card-list-gap: 1rem;
$product-card-list-lite-gap: 1.5rem;
$tablet-columns: 2;
$desktop-columns: 4;
$desktop-columns-lite: 2;
$product-card-max-width: 15rem;
$product-card-max-width-small: 100%;

.product-card-list {
  gap: $product-card-list-gap;

  .product-card {
    flex-basis: 100%;

    p {
      background: $bg-gradient;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @include media-breakpoint-up("md") {
      flex-basis: calc((100% / $tablet-columns) - $product-card-list-gap);
    }

    @include media-breakpoint-up("xl") {
      flex-basis: calc((100% / $desktop-columns) - $product-card-list-gap);
      max-width: $product-card-max-width;
    }
  }

  &__lite {
    gap: $product-card-list-gap;

    @include media-breakpoint-up("md") {
      gap: $product-card-list-lite-gap;
    }

    .product-card {
      flex-basis: 100%;

      @include media-breakpoint-up("md") {
        flex-basis:
          calc(
            (100% / $desktop-columns-lite) - $product-card-list-gap
          );
        max-width: $product-card-max-width-small;
      }
    }
  }
}
