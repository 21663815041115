// scss-docs-start form-range-variables
$form-range-track-width: 100% !default;
$form-range-track-height: 0.25rem !default;
$form-range-track-height-lg: 0.375rem !default;
$form-range-track-cursor: pointer !default;
$form-range-track-bg: rgba($component-active-bg, 0.35) !default;
$form-range-track-bg-update: $subtle !default;
$form-range-track-fill-bg: $component-active-bg !default;
$form-range-track-fill-bg-update: $component-active-link !default;
$form-range-track-border-radius: 1px !default;
$form-range-track-box-shadow: $box-shadow-inset !default;
$form-range-thumb-width: 1.5rem !default;
$form-range-thumb-width-lg: 2rem !default;
$form-range-thumb-height: $form-range-thumb-width !default;
$form-range-thumb-height-lg: $form-range-thumb-width-lg !default;
$form-range-thumb-bg: $component-active-bg !default;
$form-range-thumb-bg-update: $component-active-link !default;
$form-range-thumb-border: 0 !default;
$form-range-thumb-border-radius: 1rem !default;
$form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1) !default;
$form-range-thumb-focus-box-shadow: 0 1px 2px rgba($black, 0.05) !default;
$form-range-thumb-focus-box-shadow-width: $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg: tint-color($component-active-bg, 70%) !default;
$form-range-thumb-active-bg-update: tint-color($component-active-link, 70%) !default;
$form-range-thumb-disabled-bg: $gray-05 !default;
$form-range-thumb-transition: background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// scss-docs-end form-range-variables
