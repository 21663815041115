$tabs-nav-link-width-xl: 357px;
$tabs-nav-link-width-md: 329px;

.nav-tabs {
  border-bottom-width: 0;

  .nav-link {
    border-right-width: 0;
    border-bottom-width: 0 !important;
    border-left-width: 0;
    color: inherit;
    padding: 0;
    margin: 0;

    &.active {
      background: none;
    }
  }

  &.tabs-vertical {
    border-bottom-width: 0;

    .nav-link {
      border-top-width: 0;
      width: $tabs-nav-link-width-md;
      @include media-breakpoint-up("xl") {
        width: $tabs-nav-link-width-xl;
      }
    }
  }
}
