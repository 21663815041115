////
// Reboot
// This file contains overrides from the original Bootstrap _reboot.scss file
////

@use "sass:map";
@import "./components/typography_v2";

body {
  @extend .typeface-body-regular;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  &:last-of-type {
    margin-bottom: 0;
  }
}

ul,
ol {
  &:last-of-type {
    margin-bottom: 0;
  }

  li {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

a {
  text-underline-offset: 3px;
}

// Mark variants

/* Rounded borders mark */
.mark-rounded {
  border-radius: $border-radius-sm;
}

/* Mark with primary background */
.mark-primary {
  color:
    map.get(
      $purple-rain-colors,
      "typography-dark-background-high-emphasis"
    );
  background-color: $primary-06;
}

.bg-gradient {
  --#{$prefix}gradient: #{$bg-gradient};

  background: $bg-gradient;
}
