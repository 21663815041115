// TODO: Refactor this.

.list-group-horizontal {
  --#{$prefix}list-group-horizontal-border-color: #{$gray-04};
  --#{$prefix}-list-group-horizontal-border-width: 1px;

  display: flex;
  flex-flow: wrap;
  padding-left: 0;
  margin-bottom: 0;
  padding-top: 1rem;
  margin-top: 1rem;
  width: 100%;
  border-radius: 0;
  border-top:
    var(--#{$prefix}-list-group-horizontal-border-width) solid
    var(--#{$prefix}list-group-horizontal-border-color);

  .list-group-horizontal-item {
    @extend .typeface-body-small;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    margin: 6px 8px;
    height: 2.5rem;

    // flex-basis: 26% because there's plenty of space for the margins, but never enough space for a forth item
    flex: 1 0 26%;

    .list-group-horizontal-item-content {
      :first-child {
        font-weight: #{$font-weight-bold} !important;
      }
    }

    .list-group-horizontal-item-content > * {
      margin: 0;
      padding: 0;
    }
  }

  .list-group-horizontal-item > * {
    margin: 0;
    padding: 0;
  }

  :first-child.list-group-horizontal-item {
    align-items: flex-start;
  }

  :first-child.list-group-horizontal-item + .list-group-horizontal-item {
    border-left:
      var(--#{$prefix}-list-group-horizontal-border-width) solid
      var(--#{$prefix}list-group-horizontal-border-color);
    border-right:
      var(--#{$prefix}-list-group-horizontal-border-width) solid
      var(--#{$prefix}list-group-horizontal-border-color);
    align-items: center;
  }

  :last-child.list-group-horizontal-item {
    align-items: flex-end;
  }
}
