@use "sass:map";

/**
  Min width is the width of the collapsed card.
  Max width corresponds to the width of the expanded card.
  When adding new breakpoint values, add new values for button-width and content-width as well
*/

/** Ways to use your equity cards */
$ways-equity-height: 195px;
$ways-equity-padding-x: map.get($spacers, 3);
$ways-equity-expanded-padding-x: map.get($spacers, 4);

/** Desktop variables */
$ways-equity-min-width: 147px;
$ways-equity-max-width: 519px;
$ways-equity-button-width: calc(
  $ways-equity-min-width - ($ways-equity-padding-x * 2)
);
$ways-equity-content-width: calc(
  $ways-equity-max-width - ($ways-equity-expanded-padding-x * 2)
);

/** Tablet variables */
$ways-equity-min-width-md: 122px;
$ways-equity-max-width-md: 260px;
$ways-equity-max-width-lg: 430px;
$ways-equity-height-md: 280px;
$ways-equity-button-width-md: calc(
  $ways-equity-min-width-md - ($ways-equity-padding-x * 2)
);
$ways-equity-content-width-md: calc(
  $ways-equity-max-width-md - ($ways-equity-expanded-padding-x * 2)
);
$ways-equity-content-width-lg: calc(
  $ways-equity-max-width-lg - ($ways-equity-expanded-padding-x * 2)
);

.ways-equity-card {
  height: $ways-equity-height-md;
  width: $ways-equity-min-width-md;
  transition: $transition-collapse-width;
  padding: 0 $ways-equity-padding-x;
  overflow: hidden;
  background-color: $white;

  @include media-breakpoint-up(xl) {
    width: $ways-equity-min-width;
    height: $ways-equity-height;
  }

  &.expanded {
    width: $ways-equity-max-width-md;
    padding: 0 $ways-equity-expanded-padding-x;

    @include media-breakpoint-up(xl) {
      width: $ways-equity-max-width;
    }
  }

  &:hover:not(.expanded) {
    background-color: $primary-01;
  }
}

.ways-equity-card-button-mobile .accordion-button:not(.collapsed),
.ways-equity-card-button-mobile .accordion-button {
  @extend .typeface-body-small;

  font-weight: $font-weight-bold;
}

.ways-equity-card-button {
  width: $ways-equity-button-width-md;

  @include media-breakpoint-up(xl) {
    width: $ways-equity-button-width;
  }
}

.ways-equity-card-content {
  width: $ways-equity-content-width-md;

  @include media-breakpoint-up(lg) {
    width: $ways-equity-content-width-lg;
  }

  @include media-breakpoint-up(xl) {
    width: $ways-equity-content-width;
  }
}
