// Range
//
// Style range inputs the same across browsers. Vendor-specific rules for pseudo
// elements cannot be mixed. As such, there are no shared styles for focus or
// active states on prefixed selectors.

.form-range-update {
  width: $form-range-track-width;
  height:
    add(
      $form-range-thumb-height-lg,
      $form-range-thumb-focus-box-shadow-width * 2
    );
  padding: 0;
  appearance: none;
  background-color: transparent;

  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --track-fill-width:
    calc(
      0.5 * #{$form-range-thumb-width} + var(--ratio) * (100% - #{$form-range-thumb-width})
    );

  &:focus {
    outline: 0;

    // Pseudo-elements must be split across multiple rulesets to have an effect.
    // No box-shadow() mixin for focus accessibility.
    &::-webkit-slider-thumb {
      box-shadow: $form-range-thumb-focus-box-shadow;
    }

    &::-moz-range-thumb {
      box-shadow: $form-range-thumb-focus-box-shadow;
    }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    width: $form-range-thumb-width-lg;
    height: $form-range-thumb-height-lg;
    margin-top: ($form-range-track-height-lg - $form-range-thumb-height-lg) * 0.5;
    appearance: none;
    @include gradient-bg($form-range-thumb-bg-update);

    border: $form-range-thumb-border;
    @include border-radius($form-range-thumb-border-radius);
    @include box-shadow($form-range-thumb-box-shadow);
    @include transition($form-range-thumb-transition);

    &:active {
      @include gradient-bg($form-range-thumb-active-bg-update);
    }
  }

  &::-webkit-slider-runnable-track {
    width: $form-range-track-width;
    height: $form-range-track-height-lg;
    color: transparent; // Why?
    cursor: $form-range-track-cursor;
    background:
      linear-gradient(
        $form-range-track-fill-bg-update,
        $form-range-track-fill-bg-update
      )
      0 / var(--track-fill-width) 100% no-repeat $form-range-track-bg-update;
    border-color: transparent;
    @include border-radius($form-range-track-border-radius);
    @include box-shadow($form-range-track-box-shadow);
  }

  &::-moz-range-thumb {
    width: $form-range-thumb-width;
    height: $form-range-thumb-height;
    appearance: none;
    @include gradient-bg($form-range-thumb-bg-update);

    border: $form-range-thumb-border;
    @include border-radius($form-range-thumb-border-radius);
    @include box-shadow($form-range-thumb-box-shadow);
    @include transition($form-range-thumb-transition);

    &:active {
      @include gradient-bg($form-range-thumb-active-bg-update);
    }
  }

  &::-moz-range-track {
    width: $form-range-track-width;
    height: $form-range-track-height-lg;
    color: transparent;
    cursor: $form-range-track-cursor;
    background-color: $form-range-track-bg-update;
    border-color: transparent; // Firefox specific?
    @include border-radius($form-range-track-border-radius);
    @include box-shadow($form-range-track-box-shadow);
  }

  &:disabled {
    pointer-events: none;

    &::-webkit-slider-thumb {
      background-color: $form-range-thumb-disabled-bg;
    }

    &::-moz-range-thumb {
      background-color: $form-range-thumb-disabled-bg;
    }
  }
}

// Styles for the boostrap component while the actual slider component is created in our UI package
.form-range-v2 {
  &::-webkit-slider-thumb {
    @include gradient-bg(#184c7a);

    &:active {
      @include gradient-bg(#184c7a);
    }
  }

  &::-webkit-slider-runnable-track {
    background:
      linear-gradient(
        #184c7a,
        #184c7a
      )
      0 / var(--track-fill-width) 100% no-repeat #d4dcdf;

    @include border-radius(4px);
  }

  &::-moz-range-thumb {
    @include gradient-bg(#184c7a);

    &:active {
      @include gradient-bg(#184c7a);
    }
  }

  &::-moz-range-track {
    background-color: #d4dcdf;
    @include border-radius(4px);
  }
}
