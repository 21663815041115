@import "bootstrap/scss/functions";
@import "purple-rain/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/reboot";
@import "purple-rain/scss/reboot";

// Components
// Custom hero styles
@import "./components/dashboard/hero";

// Custom product card styles
@import "./components/dashboard/product_card";

// Custom mortgage value styles
@import "./components/dashboard/mortgage-value";

// Custom estimated equity styles
@import "./components/dashboard/estimated-equity";

// Custom not found styles
@import "./components/dashboard/not-found";

// Custom graph styles
@import "./components/dashboard/graph";

// Custom sign up styles
@import "./components/dashboard/sign-up";

// Custom Products Page styles
@import "components/products/products-page";

// Custom Products Summary styles
@import "components/products/summary";

// Custom ways to use your equity styles
@import "components/dashboard/ways-equity";

// Custom Products Steps styles
@import "./components/products/steps";

// Custom How it Works styles
@import "./components/products/how-it-works";

// Custom Products Loan Options styles
@import "components/products/loan-options";

// Custom Products Component Styles
@import "components/products/feature-list-simple";

// Custom personal goals styles
@import "components/dashboard/personal-goals";

// Custom Modal Terms And Conditions
@import "components/dashboard/terms-and-conditions-modal";

// Custom complete your profile styles
@import "./components/dashboard/complete-your-profile";

// Custom SSO Banner styles
@import "components/dashboard/sso-banner";

// Custom SSO Rejected error styles
@import "components/dashboard/sso-rejection";

// Custom Content Block styles
@import "components/dashboard/content-block-overlay";

// Custom resource center article styles
@import "components/dashboard/resource-center-article";

// Custom disclosure styles
@import "components/dashboard/disclosures";

// Custom ADA Accessibility styles
@import "components/dashboard/ada-accessibility";

// Custom Next Steps styles
@import "components/dashboard/next-steps";

// Custom Cash Out Loan Quote styles
@import "components/dashboard/cash-out-loan-quote";
@import "components/dashboard/step-hero-banner";
@import "components/dashboard/borrower-details";

// Lite brand refresh
@import "lite-brand/typography";
@import "lite-brand/buttons";

// Custom Insurance Ad styles
@import "components/dashboard/insurance-ad";

// Custom Home Equity Loan styles
@import "components/dashboard/home-equity-loan";

// Digital Account Creation
@import "components/dashboard/digital-account-creation";

// Lakeview Blue Dashboard
@import "components/dashboard/lakeview-blue-dashboard";

// Mortgage Card / Floating Button
@import "components/dashboard/mortgage-card";

// Custom Ribbon styles
@import "components/dashboard/ribbon";

// Form input styles
@import "components/dashboard/form-input";

.app-component {
  &.splash.fade-in {
    animation-delay: 0.75s;
    opacity: 0;
  }

  & .is-floating-button {
    @include media-breakpoint-down(md) {
      padding-bottom: 56px;
    }
  }
}

.lakeview-rebrand {
  @apply tw-font-sans tw-text-regular tw-text-primary;

  h1,
  h2,
  h3 {
    @apply tw-font-serif tw-text-heading tw-font-normal tw-text-pretty;
  }

  h1 {
    & ~ p {
      @apply tw-text-secondary;
    }
  }

  a {
    &:not([type="button"], [type="submit"], [type="reset"]) {
      @apply tw-text-link-rest hover:tw-text-link-hover active:tw-text-link-pressed;
    }
  }
}
