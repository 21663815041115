// If you're able to achieve your customization via the `_variables.scss` file
// then put additional CSS here

// Custom Form styles
@import "./components/form_check";
@import "./components/floating-labels";
@import "./components/form-control";
@import "./components/form";
@import "./components/input-group";
@import "./components/form-range";
@import "./components/form-range-update";

// list-group-horizontal
@import "./components/list-group-horizontal";

// Custom card styles
@import "./components/cards";

// Custom tags styles
@import "./components/tags";

// Custom nav/tab styles
@import "./components/tabs";

// Animations
.fade-in {
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-name: fade-in;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// Typography
.text-justify {
  text-align: justify;
}

// Allows to set an svg stroke color via the "color" property
.svg-color-util {
  fill: currentcolor;

  path {
    fill: currentcolor;
  }
}
