$how-it-works-img-max-width: 531px;
$how-it-works-content-width: 413px;

.how-it-works-img {
  max-width: $how-it-works-img-max-width;

  @include media-breakpoint-up("xl") {
    width: $how-it-works-img-max-width;
  }
}

.how-it-works-content {
  @include media-breakpoint-up("xl") {
    width: $how-it-works-content-width;
  }
}
