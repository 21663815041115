// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
@use "sass:map";

@if $enable-container-classes {
  .container,
  .container-fluid {
    @include media-breakpoint-down(sm) {
      padding-right: $spacer;
      padding-left: $spacer;
    }
  }
}
