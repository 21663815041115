:root {
  // Tooltip styles
  --history-value-graph-tooltip-font-weight: #{$font-weight-normal};
  --history-value-graph-tooltip-font-family: #{$subtitle-02-font-family};
  --history-value-graph-tooltip-font-letter-spacing: #{$subtitle-02-letter-spacing};
  --history-value-graph-tooltip-value-line-height: #{$subtitle-02-line-height};

  // x-axis styles
  --history-value-graph-x-axis-font-weight: #{$font-weight-bold};
  --history-value-graph-x-axis-font-family: #{$caption-font-family};
  --history-value-graph-x-axis-font-letter-spacing: 1.82px;
  --history-value-graph-x-axis-font-line-height: #{$caption-line-height};

  // y axis styles
  --history-value-graph-y-axis-font-weight: #{$font-weight-bold};
  --history-value-graph-y-axis-font-family: #{$caption-font-family};
  --history-value-graph-y-axis-font-letter-spacing: #{$caption-letter-spacing};
  --history-value-graph-y-axis-font-line-height: #{$caption-line-height};

  // Label styles
  --history-value-graph-label-font-weight: #{$font-weight-normal};
  --history-value-graph-label-font-family: #{$caption-font-family};
  --history-value-graph-label-font-letter-spacing: #{$caption-letter-spacing};
  --history-value-graph-label-font-line-height: #{$caption-line-height};
  --history-value-graph-label-font-color: rgb(0 0 0 / 75%);
}

// absolute positioning of the label on top of the history value graph
.history-graph-label {
  bottom: 15%;
  right: 30px;
  color: var(--history-value-graph-label-font-color);
}
