// Breakdown bar graph variables
$mortgage-offcanvas-graph-height: 16px;
$mortgage-graph-label-circle-size: 16px;
$mortgage-modal-payment-card-min-width-md: 280px;
$mortgage: (
  "mortgage-primary": #9f9dff,
  "mortgage-secondary": #f89d81,
  "mortgage-tertiary": #caddbb
);

.mortgage-container {
  // Generate variables for mortgage breakdown colors
  @each $color, $value in $mortgage {
    --#{$ui-library-prefix}#{$color}: #{$value};
  }
}

.mortgage-offcanvas-graph {
  height: $mortgage-offcanvas-graph-height;
}

.mortgage-graph-label-circle {
  width: $mortgage-graph-label-circle-size;
  height: $mortgage-graph-label-circle-size;
}

.mortgage-modal-payment-card {
  @include media-breakpoint-up(md) {
    min-width: $mortgage-modal-payment-card-min-width-md !important;
  }
}
