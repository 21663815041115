@import "variables";
@import "../../components/typography_v2";

[data-bs-theme="lakeview-blue"] {
  --font-family-sans-serif: #{$font-family-sans-serif-lakeview-blue};
  --font-family-serif: #{$font-family-serif-lakeview-blue};
  --#{$prefix}link-opacity: 1;
  --#{$prefix}heading-font-weight: 700;

  // Button
  .btn {
    --#{$prefix}btn-font-size: #{$btn-font-size-lakeview-blue};
    --#{$prefix}btn-font-weight: #{$btn-font-weight-lakeview-blue};
    --#{$prefix}btn-line-height: #{$btn-line-height-lakeview-blue};

    text-transform: none;
  }

  .btn-primary {
    background-image: none;

    &:hover,
    &:active,
    &.active,
    &:focus,
    &.focus {
      box-shadow: none;
      opacity: 1;
    }
  }

  // NavBar
  .navbar-light {
    .link-hover {
      line-height: 24px;
    }
  }

  // Styles for menu links present in the header menus (dropdown and hamburger)
  .menu-link {
    transition: $transition-base;
  }

  // Link
  a {
    text-underline-offset: 2px;
  }
}
