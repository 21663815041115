.step-hero-banner {
  position: relative;
  height: 120px;

  @include media-breakpoint-up(sm) {
    height: 240px;
  }

  @include media-breakpoint-up(lg) {
    height: 280px;
  }

  &__gradient::before,
  &__image,
  &__back-button {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__image {
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }

  &__gradient {
    &::before {
      background: $hero-banner-gradient;
      content: "";
      z-index: 2;
    }
  }

  &__back-button {
    z-index: 3;
  }
}
