@use "sass:map";

$footer-mobile-height: 88px;
$footer-desktop-height: 104px;

.cash-out-loan-quote {
  position: relative;
  min-height: calc(100vh - $navbar-height - $footer-mobile-height);

  @include media-breakpoint-up(md) {
    min-height: calc(100vh - $navbar-desktop-height - $footer-desktop-height);
  }

  &__shift-up {
    position: relative;
    transform: translateY(-(map.get($spacers, 5)));
    z-index: 5;
  }

  &__success {
    h2 {
      line-height: 1.3;
    }
  }

  &__occupancy-card {
    .label {
      @include media-breakpoint-up(sm) {
        max-width: min-content;
      }
    }
  }

  &__banner_image {
    img {
      object-position: center 90%;

      @include media-breakpoint-up(md) {
        object-position: center 70%;
      }
    }
  }

  &__scroll_down {
    letter-spacing: 2.4px;
  }

  &__card-logo {
    height: 40px;

    @include media-breakpoint-up(md) {
      height: 50px;
    }

    @include media-breakpoint-up(xl) {
      height: 56px;
    }
  }
}
